import React from "react";
import { useDeleteformateurMutation } from "../../api/formateurApi";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FiEdit2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";

const FormateurLine = ({ data, refetch, val }) => {
  const [deleteFormateur] = useDeleteformateurMutation();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletec = async () => {
    const res = await deleteFormateur({ id: data?._id });
    if (res.data) {
      toast.success("successfully removed");
      refetch();
      setOpen(false);
    } else {
      toast.error("failed to delete");
    }
  };
  return (
    <>
      <tr className="hover:bg-gray-50 duration-100">
        <td className="py-4 ps-6">
          <div className="flex items-center justify-center hover:bg-gray-100 w-8 h-8 rounded-full">
            <input
              id="hs-table-checkbox-1"
              type="checkbox"
              className="border-gray-800 rounded-xl text-blue-600 border focus:ring-blue-500"
            />
            <label htmlFor="hs-table-checkbox-1" className="sr-only">
              Checkbox
            </label>
          </div>
        </td>
        <td
          className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 "
          title={data.Nome}
        >
          {data.Nom}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
          {data.description.fr.slice(0, 15)}...
        </td>
        <td className="pl-7 py-4">
          <div className="w-10 h-10 overflow-hidden">
            <img
              src={data.image}
              alt=""
              className="object-cover h-full w-full"
            />
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
          <div className="flex gap-x-3 items-center">
            <Link
              to={`edit/${data._id}`}
              className="w-8 h-8 hover:bg-gray-100 rounded-full text-gray-600 flex items-center justify-center"
            >
              <FiEdit2 className="text-xl" />
            </Link>
            <span
              onClick={handleClickOpen}
              className="w-8 h-8 hover:bg-gray-200/50 text-gray-600 rounded-full flex items-center justify-center"
            >
              <MdDeleteOutline className="text-xl" />
            </span>
          </div>
        </td>
      </tr>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confimer la suppresion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cette action supprimera définitivement cette élément de votre base
            de données !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-end items-center gap-x-2 py-3 px-4">
            <button
              onClick={handleClose}
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Fermer
            </button>
            <button
              type="button"
              onClick={deletec}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#6366F1] text-white hover:bg-[#5f61ef] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Confirmer
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormateurLine;
