import React, { useState } from "react";
import BodyHead from "../../components/BodyHead";
import { color } from "../../static/static";
import InputOwn from "../../components/InputOwn";

const CreateUser = () => {
  const [nom, setNom] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [date_naissance, setDateNaissance] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title="Créer un utilisateur" />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">Créer un utilisateur</h3>
        <div className="mt-5">
          <form action="">
            {(
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Username"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <InputOwn
                  label="email"
                  type="email"
                  value={email}
                  modifier={setEmail}
                />
                <InputOwn
                  label="Numéro de téléphone"
                  type="number"
                  value={phone}
                  modifier={setPhone}
                />
                <InputOwn
                  label="Date de naissance"
                  type="date"
                  value={date_naissance}
                  modifier={setDateNaissance}
                />
                <InputOwn
                  label="Photo de profil"
                  type="file"
                  value={image}
                  Hstyle="col-span-2"
                  modifier={setImage}
                />
              </div>
            )}
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Valider
              </button>
              <button
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
