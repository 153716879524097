import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ModuleApi = createApi({
  reducerPath: "ModuleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
        headers.set("Content-type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllModules: builder.query({
      query: () => "/module/",
    }),
    getOneModule: builder.query({
      query: ({id}) => `/module/${id}`,
    }),
    createModule: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/module/add/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getListModule: builder.query({
      query: () => {
        return {
          url: `/module/listModule`
        }
      },
    }),
    deleteModule: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/module/${id}`,
          method: "DELETE",
        };
      },
    }),
    editModule: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/module/${id}`,
          method: "PUT",
          body: data
        };
      },
    }),
  }),
});

export const {
    useCreateModuleMutation,
    useDeleteModuleMutation,
    useEditModuleMutation,
    useGetAllModulesQuery,
    useGetOneModuleQuery,
    useLazyGetOneModuleQuery,
    useGetListModuleQuery
} = ModuleApi;
