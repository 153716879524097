import React, { useEffect, useState } from "react";
import BodyHead from "../../components/BodyHead";
import { color } from "../../static/static";
import InputOwn from "../../components/InputOwn";
import { useParams } from "react-router-dom";
import {
  useEditFormationMutation,
  useGetAllFormationsQuery,
} from "../../api/formationApi";
import toast from "react-hot-toast";

function EditFormationFrom() {
  const { id } = useParams();
  const { data, refetch } = useGetAllFormationsQuery();
  // const { data } = useGetOneFormationQuery({ id: id });
  const [editFomation] = useEditFormationMutation();

  const [nom, setNom] = useState("");
  const [video, setVideo] = useState("");
  const [description, setDescription] = useState("");
  const [tittre1, setTitre1] = useState("");
  const [tittre2, setTitre2] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");

  const handleSubmit = async (e) => {
    const titres = [tittre1, tittre2];
    const descriptions = [description1, description2];
    e.preventDefault();
    const res = await editFomation({
      id: id,
      data: { nom, video, description, titres, descriptions },
    });
    if (res.data) {
      toast.success("updated succefully")
      refetch();
    }else{
      toast.error("Failed to update")
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setNom("");
    setDescription("");
    setVideo("");
  };

  useEffect(() => {
    if (data) {
      setNom(data[0].nom);
      setVideo(data[0].video);
      setDescription(data[0].description);
      setTitre1(data[0].titres[0]);
      setTitre2(data[0].titres[1]);
      setDescription1(data[0].descriptions[0])
      setDescription2(data[0].descriptions[1])
    }
  }, [data]);

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title="Editer" />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">Editer la formation</h3>
        <div className="mt-5">
          <form action="">
            <div className="grid grid-cols-2 gap-5">
              <InputOwn
                label="Nom de la formation"
                type="text"
                value={nom}
                modifier={setNom}
              />
              <InputOwn
                label="Lien de la vidéo de présentation"
                type="url"
                value={video}
                modifier={setVideo}
              />
              <InputOwn
                label="Numéro de téléphone"
                type="number"
                Hstyle={"col-span-2"}
                children={
                  <textarea
                    placeholder="Descripion de la formation"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                  ></textarea>
                }
              />
              <InputOwn
                label="Titre 1"
                type="text"
                value={tittre1}
                modifier={setTitre1}
              />
              <InputOwn
                label="Titre 2"
                type="text"
                value={tittre2}
                modifier={setTitre2}
              />
              <InputOwn
                label="Numéro de téléphone"
                type="number"
                Hstyle={"col-span-1"}
                children={
                  <textarea
                    placeholder="Descripion du titre 1"
                    value={description1}
                    onChange={(e) => setDescription1(e.target.value)}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                  ></textarea>
                }
              />
              <InputOwn
                label="Numéro de téléphone"
                type="number"
                Hstyle={"col-span-1"}
                children={
                  <textarea
                    placeholder="Descripion du tittre 2"
                    value={description2}
                    onChange={(e) => setDescription2(e.target.value)}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                  ></textarea>
                }
              />
            </div>
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Modifier
              </button>
              <button
                onClick={handleReset}
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditFormationFrom;
