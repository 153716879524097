import React, { useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import Statistic from "../../components/Statistic";
import { faqsList, mainExchange } from "../../static/static";
import btc from "../../assets/btc.png";
import ScrollToAnchor from "../../components/ScrollToAnchor";
import { useTranslation } from "react-i18next";
import { useGetAllExchangeQuery } from "../../api/contenuApi";
import FaqsCard from "../../components/FaqsCard";

// [#FBCD13]

const Exchange = () => {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [toogle, setToogle] = useState(true);
  const { data: exchange, isSuccess: exSuccces } = useGetAllExchangeQuery();
  console.log(exchange);
  return (
    <div>
      <ScrollToAnchor />
      {/* ------------------Main block--------------------- */}
      <div className="px-[7.5%] w-full py-10 dark:bg-slate-900/95 bg-white">
        {exSuccces && (
          <Box>
            <div>
              <h3 className="dark:text-gray-400 text-gray-500 text-lg">
                {lang === "en"
                  ? exchange[0].en.soutitre
                  : exchange[0].en.soutitre}
              </h3>
              <Statistic
                main={lang === "en" ? exchange[0].en.titre : exchange[0].titre}
                text={
                  lang === "en"
                    ? exchange[0].en.text.slice(0, 130)
                    : exchange[0].text.slice(0, 130) + "."
                }
                dark={"dark:text-white"}
                stitle={
                  "text-[40px] md:text-[54px] mb-8 font-medium leading-tight capitalize max-w-2xl"
                }
                sp={"mt-4 text-lg text-gray-600 dark:text-white/60 max-w-xl"}
              />
              <div className="flex gap-x-3 mt-8">
                <Button
                  label={t("Exchange", { returnObjects: true }).btn2}
                  link={mainExchange.links[0].path}
                  white={"bg-gray-800 text-gray-100 rounded-full py-4 px-8"}
                  dark={"dark:bg-[#FBCD13] dark:text-black"}
                />
              </div>
            </div>
            <img
              src={exchange[0].image}
              alt=""
              className="flex max-w-[460px] object-cover"
            />
          </Box>
        )}
      </div>
      <div className="w-full px-[6.5%] pt-12 dark:bg-slate-900/95">
        <section class="bg-gray-800 dark:bg-gray-900 py-4 rounded-2xl">
          <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
            <h2 class="max-w-2xl mx-auto text-2xl font-medium tracking-tight text-gray-100 xl:text-4xl mb-2 dark:text-white">
              Bring your Business to the{" "}
              <span class="text-blue-500">next level.</span>
            </h2>

            {/* <p class="max-w-4xl mt-6 text-center text-gray-500 dark:text-gray-300">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum
              quidem officiis reprehenderit, aperiam veritatis non, quod veniam
              fuga possimus hic explicabo laboriosam nam. A tempore totam ipsa
              nemo adipisci iusto!
            </p> */}

            <div class="inline-flex w-full mt-6 sm:w-auto">
              <a
                href="#1"
                class="inline-flex items-center justify-center w-full px-10 py-3 text-white duration-300 bg-blue-600 rounded-full hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              >
                Commencer
              </a>
            </div>
          </div>
        </section>
      </div>
      {/* Buy and sell black */}
      <div className="px-[6.5%] w-full py-14 font-medium dark:bg-slate-900/95 bg-white">
        <h1 className="text-5xl font-medium text-center my-5 mt-16 dark:text-gray-50">
          Buy and sell crypto
        </h1>
        <p className="text-center text-lg font-light text-gray-400 dark:text-gray-300">
          Vous pouvez acheter et revendre vos cryptomonaies chez nous très
          rapidement
        </p>
        <div className="flex justify-center w-full mt-10">
          <div className="flex bg-gray-100 rounded-full justify-center relative overflow-hidden">
            <div
              onClick={() => setToogle(true)}
              className={`px-10 py-4 cursor-pointer duration-300 rounded-full ${
                toogle
                  ? "text-gray-100 border-l-2 dark:border-yellow-500 border-gray-900"
                  : "text-gray-800"
              } z-10`}
            >
              Vendre
            </div>
            <div
              onClick={() => setToogle(false)}
              className={`px-10 py-4 cursor-pointer duration-200 rounded-full ${
                !toogle
                  ? "text-gray-100 border-r-2 dark:border-yellow-500 border-gray-900"
                  : "text-gray-800"
              } z-10`}
            >
              Acheter
            </div>
            <div
              className={`absolute bg-gray-800 dark:bg-gray-900 transition-all duration-200 top-0 bottom-0 ${
                toogle ? "left-0" : "translate-x-[50%]"
              } w-[50%] rounded-full z-0`}
            ></div>
          </div>
        </div>
        <div className="mt-24 flex justify-center">
          {toogle ? (
            <div>
              <div className="flex gap-x-16 justify-evenly w-full text-2xl border-b pb-3 items-center border-gray-200 dark:border-gray-600">
                <div className="flex items-center overflow-hidden">
                  <div className="rounded-full h-7 w-7 bg-gray-800 mr-3">
                    <img src={btc} alt="" className="w-full object-cover" />
                  </div>
                  <span className="font-medium dark:text-gray-100">
                    Bitcoin
                  </span>
                  <span className="font-light text-gray-500 ml-4 dark:text-gray-500">
                    BTC
                  </span>
                </div>
                <span className="font-semibold text-3xl dark:text-gray-50">
                  $51,720.83
                </span>
                <span className="text-lg text-green-500">+1.18%</span>
                <span className="text-lg dark:text-gray-100">
                  $1,015,645,389,348.30
                </span>
                <button className="text-[16px] rounded-full text-gray-100 px-8 py-2 bg-gray-800 dark:bg-blue-500">
                  Vendre
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex gap-x-16 justify-evenly w-full text-2xl border-b pb-3 items-center border-gray-200 dark:border-gray-600">
                <div className="flex items-center overflow-hidden">
                  <div className="rounded-full h-7 w-7 bg-gray-800 mr-3">
                    <img src={btc} alt="" className="w-full object-cover" />
                  </div>
                  <span className="font-medium dark:text-gray-100">
                    Bitcoin
                  </span>
                  <span className="font-light text-gray-500 ml-4 dark:text-gray-500">
                    BTC
                  </span>
                </div>
                <span className="font-semibold text-3xl dark:text-gray-50">
                  $51,720.83
                </span>
                <span className="text-lg text-green-500">+1.18%</span>
                <span className="text-lg dark:text-gray-100">
                  $1,015,645,389,348.30
                </span>
                <button className="text-[16px] rounded-full text-gray-100 px-8 py-2 bg-gray-800 dark:bg-yellow-400 dark:text-gray-800">
                  Achter
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* -------------------------News Card----------------------- */}
      <div className="w-full py-8 pt-28 dark:bg-slate-900/95">
        <section class="bg-gray-800 dark:bg-gray-900 py-4">
          <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
            <h2 class="max-w-2xl mx-auto text-2xl font-medium tracking-tight text-gray-100 xl:text-4xl mb-2 dark:text-white">
              Bring your Business to the{" "}
              <span class="text-blue-500">next level.</span>
            </h2>

            {/* <p class="max-w-4xl mt-6 text-center text-gray-500 dark:text-gray-300">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cum
              quidem officiis reprehenderit, aperiam veritatis non, quod veniam
              fuga possimus hic explicabo laboriosam nam. A tempore totam ipsa
              nemo adipisci iusto!
            </p> */}

            <div class="inline-flex w-full mt-6 sm:w-auto">
              <a
                href="#1"
                class="inline-flex items-center justify-center w-full px-10 py-3 text-white duration-300 bg-blue-600 rounded-full hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              >
                Commencer
              </a>
            </div>
          </div>
        </section>
      </div>
      {/* -------------------FAQ section ---------------------------- */}
      <div className="px-[6.5%] w-full py-16 pb-20 dark:bg-slate-900/95">
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
          <div className="space-y-3 text-center">
            <h1 className="text-4xl text-gray-800 font-medium dark:text-gray-100">
              Frequently Asked Questions
            </h1>
            <p className="text-gray-600 max-w-lg mx-auto text-lg dark:text-gray-400 mt-2">
              Answered all frequently asked questions, Still confused? feel free
              to contact us.
            </p>
          </div>
          <div className="mt-14 max-w-2xl mx-auto">
            {faqsList.map((item, idx) => (
              <FaqsCard idx={idx} faqsList={item} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Exchange;
