import React, { useMemo, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Session from "./Session";

const reducer = (accumulator, duree) => {
  const accD = accumulator?.duree ? accumulator.duree : accumulator;
  const nextD = duree?.duree;
  return Number(accD) + Number(nextD);
};

const AnimatedAcordeon = ({ data, open, buy }) => {
  const videoTime = useMemo(() => {
    const time = data.video.reduce(reducer);
    return time;
  }, [data]);
  const [show, setshow] = useState(false);
  return (
    <ul className="grid grid-cols-1 w-full border-b border-gray-300 dark:border-gray-600">
      <div
        className="cursor-pointer text-gray-600 flex justify-between max-[550px]:px-2 px-8 py-4 w-full items-center bg-gray-100 dark:bg-gray-600/70 backdrop-blur-sm"
        onClick={() => setshow(!show)}
      >
        <span className="max-[650px]:text-[14px] max-[450px]:text-[11px] flex gap-x-3 items-center ">
          <IoIosArrowDown className="dark:text-gray-300" />
          <span className="font-semibold dark:text-gray-300">{data?.nom}</span>
        </span>
        <span className="text-sm text-gray-500 dark:text-gray-300">
          {data.video.length} sessisons. {} min
        </span>
      </div>
      <div
        className={`flex flex-col ${
          show ? "block" : "hidden"
        } overflow-hidden duration-100 px-8 py-5`}
      >
        {data?.video?.map((el, key) => (
          <Session data={el} key={key} open={open} acheter={buy} />
        ))}
      </div>
    </ul>
  );
};

export default AnimatedAcordeon;
