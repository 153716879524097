import React, { useEffect, useState } from "react";
import BodyHead from "../../components/BodyHead";
import { color } from "../../static/static";
import InputOwn from "../../components/InputOwn";
import { useGetListFormationQuery } from "../../api/formationApi";
import {
  useEditAcademiMutation,
  useGetAllAcademiQuery,
  useGetOneAcademiQuery,
} from "../../api/AcademiApi";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const EditAcademi = () => {
  const { data, isLoading, isSuccess } = useGetListFormationQuery();
  const [editAcademi] = useEditAcademiMutation();
  const { refetch } = useGetAllAcademiQuery();

  const { id } = useParams();

  const { data: academi, refetch: oneRef } = useGetOneAcademiQuery({ id: id });

  const [nom, setNom] = useState("");
  const [duration, setduration] = useState("");
  const [title, setTitle] = useState("");
  const [prix, setprix] = useState("");
  const [video, setVideo] = useState("");
  const [formation, setFormation] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await editAcademi({
      id: id,
      data: { nom, duration, title, prix, video, description, formation },
    });
    if (res.data) {
      toast.success("Mise à jour avec succès");
      oneRef();
      refetch();
    } else {
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    setNom(academi?.nom);
    setduration(academi?.duration);
    setTitle(academi?.title);
    setprix(academi?.prix);
    setVideo(academi?.video);
    setFormation(academi?.formation);
    setDescription(academi?.description);
  }, [academi]);

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title="Editer une academie" />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">Editer une académie</h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Nom de l'académie"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <InputOwn
                  label="Durée de l'academie"
                  type="number"
                  value={duration}
                  modifier={setduration}
                />
                <InputOwn
                  label="Entrer le titre de l'académie"
                  type="text"
                  Hstyle={"col-span-2"}
                  value={title}
                  modifier={setTitle}
                />
                <InputOwn
                  label="Entrez le prix de l'académie"
                  type="number"
                  value={prix}
                  modifier={setprix}
                />
                <InputOwn
                  label="Entrez le lien de la vidéo de présentation de la vidéo"
                  type="url"
                  value={video}
                  modifier={setVideo}
                />
                <div className="relative inline-block col-span-2">
                  <select
                    defaultValue=""
                    disabled
                    className="block appearance-none w-full text-gray-500 text-sm bg-white border border-gray-200 hover:border-gray-500 px-4 py-4 active:right-2 pr-8 rounded-lg leading-tight focus:outline-blue-600 focus:border-blue-500"
                    value={formation}
                    onChange={(e) => {
                      setFormation(e.target.value);
                    }}
                    placeholder="Choisisez une formation"
                    //                 data-hs-select='{
                    //   "placeholder": "Selectionner une academy...",
                    //   "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-4 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                    //   "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-2 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                    //   "optionClasses": "py-3 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800"
                    // }'
                  >
                    <option value="" disabled selected>
                      Sélectionnez la formation à laquelle vous souhaitez relier
                      l'académie
                    </option>
                    {!isLoading &&
                      isSuccess &&
                      data.map((el, key) => (
                        <option key={key} value={el._id}>
                          {el.nom}
                        </option>
                      ))}
                  </select>
                  <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
                    <svg
                      className="flex-shrink-0 w-4 h-4 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <InputOwn
                  Hstyle={"col-span-2"}
                  children={
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Descripion de l'académie"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                    ></textarea>
                  }
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Editer
              </button>
              <button
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAcademi;
