import React from "react";
import { NavLink } from "react-router-dom";

const MenuIcon = ({ data, change }) => {
  return (
    <NavLink
      to={data.url}
      className={({ isActive }) =>
        `flex items-center ${isActive && "bg-gray-700/40"} ${
          isActive ? "text-[#fff] group" : "text-[#545e6f]"
        } gap-x-3 px-[12px] py-2 duration-300 hover:bg-gray-700/40 rounded overflow-hidden`
      }
    >
      <span className={`text-gray-500 text-2xl group-first:text-[#6366F1]`}>
        {<data.icon />}
      </span>
      <div
        className={`text-[14px] font-medium text-gray-400 textactive origin-left duration-200 group-first:text-gray-200 `}
      >
        {data.liens}
      </div>
      { (
        <div className="hidden group-first:flex w-3 h-3 ml-auto animate-pulse rounded-full bg-[#6366F1]/40 items-center justify-center">
          <div className="w-1 h-1 animate-bounce rounded-full bg-[#6366F1]"></div>
        </div>
      )}
    </NavLink>
  );
};

export default MenuIcon;
