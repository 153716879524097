import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Error from "./pages/Error";
import Home from "./pages/Home/Home";
import Community from "./pages/Community/Community";
import Exchange from "./pages/Exchange/Exchange";
import Contact from "./pages/Contact/Contact";
import Connexion from "./pages/Connexion";
import AuthLayout from "./components/AuthLayout";
import News from "./pages/News";
import Inscription from "./pages/Inscription";
import FormationInfo from "./pages/FormationInfo";
import IsAuthenticated from "./components/IsAuthenticated";
import DashLayout from "./Dashboard/DashLayout";
import DashHome from "./Dashboard/DashHome";
import Utilisateur from "./Dashboard/Utilisateur";
import AdminFormation from "./Dashboard/AdminFormation";
import EditUsers from "./Dashboard/UserComponent/EditUsers";
import Paiement from "./Dashboard/Paiement";
import Académie from "./Dashboard/Académie";
import Modules from "./Dashboard/Modules";
import ByeFormation from "./pages/ByeFormation";
import CreateFormation from "./Dashboard/FormationComponent/CreateFormation";
import EditFormationFrom from "./Dashboard/FormationComponent/EditFormationFrom";
import CreateModuleForm from "./Dashboard/Module/CreateModuleForm";
import CreateAcademie from "./Dashboard/Académie/CreateAcademie";
import CreateUser from "./Dashboard/UserComponent/CreateUser";
import EditAcademi from "./Dashboard/Académie/EditAcademi";
import AdminVideo from "./Dashboard/AdminVideo";
import ManageVid from "./Dashboard/Video/ManageVid";
import Profile from "./pages/Profile";
import Contenu from "./Dashboard/Contenu";
import ManageContenu from "./Dashboard/Contenu/ManageContenu";
import IsAdmin from "./components/IsAdmin";
import Formateur from "./Dashboard/Formateur";
import ManageFormateur from "./Dashboard/formateurs/ManageFormateur";
import Checksession from "./components/Checksession";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route Component={AuthLayout}>
          <Route
            path={"/paiement/:id"}
            element={
              <ByeFormation />
              // <IsAuthenticated>
              // </IsAuthenticated>
            }
          />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/connexion" element={<Connexion />} />
        </Route>
        <Route
          path="/"
          element={
            <Checksession>
              <Layout />
            </Checksession>
          }
        >
          <Route index path={""} element={<Home />} />
          <Route index path={"home"} element={<Navigate to={"/"} />} />
          <Route
            index
            path={"profile"}
            element={
              <IsAuthenticated>
                <Profile />
              </IsAuthenticated>
            }
          />
          <Route path={"exchange"} element={<Exchange />} />
          {/* <Route path={"community"} element={<Community />} /> */}
          <Route path={"news"} Component={News} />
          <Route path={"contact"} Component={Contact} />
          <Route
            path={"formation/module/:id"}
            element={
              <IsAuthenticated>
                <FormationInfo />
              </IsAuthenticated>
            }
          />
        </Route>
        <Route
          path="/Dashboard"
          element={
            <IsAuthenticated>
              <IsAdmin>
                <DashLayout />
              </IsAdmin>
            </IsAuthenticated>
          }
        >
          <Route index path="" element={<Navigate to="home" />} />
          <Route index path="home" Component={DashHome} />
          <Route path="paiement" Component={Paiement} />
          <Route path="academie" Component={Académie} />
          <Route path="contenu" Component={Contenu} />
          <Route path="contenu/edit/:id" Component={ManageContenu} />
          <Route path="contenu/create" Component={ManageContenu} />
          <Route path="academie/create" Component={CreateAcademie} />
          <Route path="academie/edit/:id" Component={EditAcademi} />
          <Route path="module" Component={Modules} />
          <Route path="module/create" Component={CreateModuleForm} />
          <Route path="module/edit/:id" Component={CreateModuleForm} />
          <Route path="utilisateur" Component={Utilisateur} />
          <Route path="utilisateur/edit/:id" Component={EditUsers} />
          <Route path="utilisateur/create/" Component={CreateUser} />
          <Route path="formation" Component={AdminFormation} />
          <Route path="formation/edit/:id" Component={EditFormationFrom} />
          <Route path="formation/create" Component={CreateFormation} />
          <Route path="formateur" Component={Formateur} />
          <Route path="formateur/edit/:id" Component={ManageFormateur} />
          <Route path="formateur/create" Component={ManageFormateur} />
          <Route path="video" Component={AdminVideo} />
          <Route path="video/create" Component={ManageVid} />
          <Route path="video/edit/:id" Component={ManageVid} />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
