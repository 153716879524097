import React from "react";
import ReactLoading from "react-loading";
import logod from "../assets/logo.png";

const Loading = () => {
  return (
    <div className="absolute w-full top-0 bottom-0 bg-gray-50 z-50 flex justify-center items-center flex-col">
      <img src={logod} alt="logo" className="w-24 animate-pulse" />
      <ReactLoading type={"cylon"} color={"#FBCD13"} width={100} />
    </div>
  );
};

export default Loading;
