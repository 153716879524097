import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const UsersApi = createApi({
  reducerPath: "Users",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/user/",
    }),
    getOneUser: builder.query({
      query: ({ id }) => `/user/${id}/`,
    }),
    getRoles: builder.query({
      query: () => `/user/check-roles/`,
    }),
    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `/user/delete/${id}`,
          method: "DELETE",
        };
      },
    }),
    updateUser: builder.mutation({
      query: ({ id, data }) => {
        console.log(data);
        return {
          url: `/user/update/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetOneUserQuery,
  useGetRolesQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
} = UsersApi;
