import React from "react";

const DataGrid = ({ header, Children}) => {
  return (
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden flex flex-col divide-y ">
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50 ">
                <tr className="">
                  <th scope="col" className="py-4 ps-6">
                    <div className="flex items-center justify-center hover:bg-gray-100 w-8 h-8 rounded-full">
                      <input
                        id="hs-table-checkbox-all"
                        type="checkbox"
                        className="border-gray-800 rounded-xl text-blue-600 border focus:ring-blue-500"
                      />
                      <label htmlFor="hs-table-checkbox-all" className="sr-only">
                        Checkbox
                      </label>
                    </div>
                  </th>
                  {header.map((el, key) => (
                    <th
                    key={key}
                    scope="col"
                    className="px-6 py-4 text-start text-sm font-medium text-gray-800 uppercase"
                  >
                    {el}
                  </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 overflow-hidden">
                {Children}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataGrid;
