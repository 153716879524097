import React from "react";
import Statistic from "./Statistic";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";

const Imgcard = ({ src, vip,  ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="flex border dark:border-none border-gray-200 hover:shadow-xl shadow-black/25 dark:hover:shadow-none flex-col md:flex-col max-w-[360px] rounded gap-x-6 items-center duration-200 bg-white/10 backdrop-blur-md">
      <div className="w-full flex justify-center items-center pt-3">
        {/* <iframe
          className="w-full"
          title="start video"
          height="250"
          frameBorder="0"
          src={src}
          allowFullScreen
        ></iframe> */}
        <img src={vip} alt="" className="w-7/12"/>
      </div>
      <div className="p-6 flex flex-col h-full gap-6">
        <Statistic
          main={props.main}
          text={props?.text?.toLowerCase()}
          dark={props.dark}
          light={props.light}
          stitle={props.stitle}
          sp={props.sp}
        />
        <Divider sx={{marginTop: "auto"}}/>
        <div className="mt-auto flex justify-between items-center">
          <Link
            to={"/formation/module/" + props.el._id}
            className="px-5 py-2.5 mt-auto rounded bg-blue-500 text-white block"
          >
            <span>{t("now")}</span>
          </Link>
          <span className="dark:text-gray-50 text-4xl font-bold">{props?.el?.prix ? `${props?.el?.prix}$` : ""}</span>
        </div>
      </div>
    </div>
  );
};

export default Imgcard;
