import React from "react";
import { Outlet } from "react-router-dom";
import AuthHeader from "./Header/AuthHeader";

const AuthLayout = () => {
  return (
    <div className="h-screen overflow-y-scroll w-[100%] flex flex-col">
      <AuthHeader />
      <div className="dark:bg-slate-900/95 h-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
