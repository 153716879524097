import { useDispatch } from "react-redux";
import "./App.css";
import Router from "./Router";
import { ThemeProvider } from "./hooks/ThemeContext";
import { loginSuccess } from "./slices/userSlice";
import "flag-icon-css/css/flag-icons.min.css";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useGetAllAcademiQuery } from "./api/AcademiApi";
import Loading from "./components/Loading";
import("preline");

function App() {
  const { isFetching } = useGetAllAcademiQuery();
  const [isvisible, setIsvisible] = useState(true);
  const dispatch = useDispatch();
  let user = null;
  try {
    user = localStorage.getItem("user");
  } catch {
    user = null;
  }
  if (user != null) {
    dispatch(loginSuccess(JSON.parse(user)));
  }
  useEffect(() => {
    if (!isFetching) {
      setIsvisible(false);
    }
  }, [isFetching]);
  return (
    <>
      <ThemeProvider>
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          toastOptions={{
            duration: 3500,
          }}
        />
        {isvisible && <Loading />}
        <Router />
      </ThemeProvider>
    </>
  );
}

export default App;
