import React from "react";
import logod from "../../assets/logoy.png";
import logow from "../../assets/logo.png";
import { useTheme } from "../../hooks/ThemeContext";
import { Link } from "react-router-dom";
import LangDropDown from "../LangDropDown";
import ShadeMode from "../ShadeMode";

const AuthHeader = () => {
  const { theme, darkQuery } = useTheme();

  return (
    <div>
      <nav className="flex flex-wrap px-[6%] justify-between items-center py-4 dark:shadow-none dark:bg-gray-900 w-full ">
        <div className="">
          <Link to="/" className="cursor-pointer flex flex-nowrap items-center">
            <img
              src={
                theme === "dark"
                  ? logod
                  : theme === "light"
                  ? logow
                  : theme === "system" && darkQuery.matches
                  ? logod
                  : logow
              }
              alt="logo"
              className="w-10 "
            />
            <h1 className="text-lg font-semibold dark:text-white">CRYPTOCOQ</h1>
          </Link>
        </div>
        <div className="flex items-center gap-y-2 gap-x-3">
          <ShadeMode />
          <LangDropDown />
        </div>
      </nav>
    </div>
  );
};

export default AuthHeader;
