import React from "react";
import { useGetRolesQuery } from "../api/userApi";
import { Navigate } from "react-router-dom";

const IsAdmin = (props) => {
  const { data, isSuccess } = useGetRolesQuery();
  return (
    isSuccess && (
      <>
        {data.access ? (
          <> {props.children}</>
        ) : (
          <>
            <Navigate to={`/error`} />
          </>
        )}
      </>
    )
  );
};

export default IsAdmin;
