import React from 'react';
import Button from './Button';

const Action = (props) => {
    return (
        <div className={props.st}>
            <h1 className='font-medium mb-4 text-3xl dark:text-white'>{props.title}</h1>
            <Button 
            link={props.path} 
            label={props.text}
            dark="dark:bg-[#fbcd13] dark:text-black"
            white="text-gray-800 bg-[#fbcd13] py-3 px-5"
            />
        </div>
    );
}

export default Action;
