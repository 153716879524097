import { Skeleton } from "@mui/material";
import React from "react";

const SkelR = () => {
  return (
    <div className="flex flex-col bg-white rounded">
      <div className="w-full flex h-full">
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
      </div>
      <div className="p-7 flex flex-col gap-y-2">
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={"100%"}/>
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} width={50} />
        <div className="flex justify-between items-center mt-auto">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
        </div>
      </div>
    </div>
  );
};

export default SkelR;
