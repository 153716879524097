import React from "react";
import { Link } from "react-router-dom";
// [#fbcd13]

const Button = ({ label, link, dark, white, a, ex }) => {
  return (
    <>
      {!a && (
        <Link to={link} target={ex ? "_blank": null}>
          <button
            className={`text-sm px-3 py-2 rounded cursor-pointer
       ${dark} ${white}
      `}
          >
            {label}
          </button>
        </Link>
      )}
      {a && (
        <a
          href="https://wa.link/sc6cln"
          target="_blank"
          rel="noreferrer"
        >
          <button
            className={`text-sm px-3 py-2 rounded cursor-pointer
       ${dark} ${white}
      `}
          >
            {label}
          </button>
        </a>
      )}
    </>
  );
};

export default Button;
