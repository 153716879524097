import React from "react";
import { MdLanguage } from "react-icons/md";
import i18next from "i18next";
import { useGetAllNewsQuery } from "../api/newsApi";

const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const LangDropDown = () => {
  const lang = localStorage.getItem("i18nextLng");
  const { refetch } = useGetAllNewsQuery({ lang: lang });
  const currentLanguage = localStorage.getItem("i18nextLng");
  return (
    <>
      <div className="hs-dropdown hidden relative md:inline-flex [--placement:bottom-right]">
        <button
          id="hs-dropdown-basic"
          type="button"
          className="hs-dropdown-toggle py-2.5 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        >
          <MdLanguage className="text-md text-gray-500 dark:text-gray-300 font-light" />
        </button>

        <div
          className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-56 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-lg shadow-black/10 dark:shadow-none rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
          aria-labelledby="hs-dropdown-basic"
        >
          {languages.map((el, key) => (
            <span
              onClick={() => {
                i18next.changeLanguage(el.code);
                refetch();
              }}
              key={el.country_code}
              className={`flex cursor-pointer items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700`}
            >
              <span className={`flag-icon flag-icon-${el.country_code}`}></span>
              <span
                className={`${
                  el.code === currentLanguage ? "text-[#FBCD13]" : ""
                }`}
              >
                {el.name}
              </span>
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default LangDropDown;
