import { createSlice } from "@reduxjs/toolkit";
// import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {
  user: null,
  isLoading: false,
  isError: false,
  isLogin: false,
  access: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.user = action.payload.user;
      state.isLogin = true;
      state.access = action.payload.token;
    },
    loginFailure: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    deconnexion: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isError = false;
      state.isLogin = false;
      state.access = false;
    },
    updateSuccess: (state, action) => {
      state.user = action.payload.user;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  deconnexion,
  updateSuccess
} = authSlice.actions;

export const selectUser = (state) => state.auth.user;
export const selectIsLoading = (state) => state.auth.isLoading;
export const selectIsError = (state) => state.auth.isError;
export const loginUser = (data) => async (dispatch) => {};

export default authSlice.reducer;
