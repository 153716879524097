import React from "react";
import { color } from "../static/static";
import NavigationState from "../Dashboard/NavigationState";
import { Link } from "react-router-dom";

const BodyHead = ({ title, bouton }) => {
  return (
    <div className="flex justify-between items-center sticky top-14 z-50 bg-white py-7">
      <div>
        <h1 className={`text-[33px] font-semibold ${color.text}`}>{title}</h1>
        <NavigationState />
      </div>
      {bouton && (
        <Link
          to={"create"}
          className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
        >
          {bouton ? bouton : "New Dashboard"}
        </Link>
      )}
    </div>
  );
};

export default BodyHead;
