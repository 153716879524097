import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import toast from "react-hot-toast";
import { useDeleteUserMutation } from "../../api/userApi";

const UserLines = ({ data, refetch }) => {
  const [deleteUser] = useDeleteUserMutation()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deletec = async () => {
    const res = await deleteUser(data?._id);
    if (res.data) {
      toast.success("successfully removed");
      refetch();
      setOpen(false);
    }else{
      toast.error("failed to delete")
    }
  };
  return (
    <>
      <tr className="hover:bg-gray-50 duration-100">
        <td className="py-4 ps-6">
          <div className="flex items-center justify-center hover:bg-gray-100 w-8 h-8 rounded-full">
            <input
              id="hs-table-checkbox-1"
              type="checkbox"
              className="border-gray-800 rounded-xl text-blue-600 border focus:ring-blue-500"
            />
            <label htmlFor="hs-table-checkbox-1" className="sr-only">
              Checkbox
            </label>
          </div>
        </td>
        <td className="px-6 py-5 whitespace-nowrap text-sm font-medium text-gray-800 ">
          <div className="flex gap-x-2 items-center">
            <Avatar
              sx={{ width: "35px", height: "35px" }}
              src={`${data.image}`}
            />
            <div className="flex flex-col">
              <h3>{data.nom}</h3>
              <span className="text-sm text-gray-400 font-normal">
                {data?.email}
              </span>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
          {data.phone}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
          {data.createdAt.split('T')[0]}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
          {data.status}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
          <div className="flex gap-x-3 items-center">
            <Link
              to={`edit/${data._id}`}
              className="w-8 h-8 hover:bg-gray-100 rounded-full text-gray-600 flex items-center justify-center"
            >
              <FiEdit2 className="text-xl" />
            </Link>
            <span
              className="w-8 h-8 hover:bg-gray-200/50 text-gray-600 rounded-full flex items-center justify-center"
              onClick={handleClickOpen}
            >
              <MdDeleteOutline className="text-xl" />
            </span>
          </div>
        </td>
      <Modal id={data._id} />
      </tr>
      <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confimer la suppresion
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Cette action supprimera définitivement cette élément de votre base
              de données !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4">
              <button
                onClick={handleClose}
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Fermer
              </button>
              <button
                type="button"
                onClick={deletec}
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#6366F1] text-white hover:bg-[#5f61ef] disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Confirmer
              </button>
            </div>
          </DialogActions>
        </Dialog>
    </>
  );
};

export default UserLines;
