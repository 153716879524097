import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const actualiteApi = createApi({
  reducerPath: "actualiteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllActualite: builder.query({
      query: () => "/actualite/",
    }),
    createActualite: builder.mutation({
      query: ({ data }) => {
        return {
          url: "/actualite/",
          method: "POST",
          body: data,
        };
      },
    }),
    updateActualite: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/actualite/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useCreateActualiteMutation,
  useGetAllActualiteQuery,
  useUpdateActualiteMutation,
} = actualiteApi;
