import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const VideoApi = createApi({
  reducerPath: "VideoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
        headers.set("Content-type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllVideo: builder.query({
      query: () => "/video/",
    }),
    getOneVideo: builder.query({
      query: ({ id }) => `/video/${id}`,
    }),
    createVideo: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/video/add/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteVideo: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/video/${id}`,
          method: "DELETE",
        };
      },
    }),
    editVideo: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/video/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useCreateVideoMutation,
  useDeleteVideoMutation,
  useEditVideoMutation,
  useGetAllVideoQuery,
  useGetOneVideoQuery,
  useLazyGetOneVideoQuery,
} = VideoApi;
