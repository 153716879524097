import React from "react";

const Statistic = ({ main, text, dark, light, stitle, sp }) => {
  return (
    <div className={`${dark} ${light}`}>
      <h1 className={`${stitle}`}>
        {main}
      </h1>
      <p className={`${sp}`}>{text}</p>
    </div>
  );
};

export default Statistic;
