import React from "react";
import { useTranslation } from "react-i18next";

const Mentorship = ({ data }) => {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  return (
    <div class="flex flex-col lg:flex-row-reverse w-full lg:justify-center items-center gap-8 lg:gap-28 rounded-lg">
      <div className="h-[350px] lg:w-[400px] overflow-hidden rounded-lg lg:flex-none">
        <img
          alt="1 on 1 mentorship"
          draggable="false"
          loading="lazy"
          decoding="async"
          data-nimg="1"
          className="object-cover w-full select-none duration-150 transition-[opacity]"
          src={data?.image}
        />
      </div>
      <div class=" flex flex-col gap-6 max-w-lg">
        <h3 class="font-sans text-4xl lg:text-5xl max-md:text-b2 max-md:font-medium dark:text-gray-50">
          {lang === "en" ? data?.titre.en : data?.titre.fr}
        </h3>
        <p class="max-w-[670px] text-sub3 dark:text-gray-100">
          {lang === "en" ? data?.text.en : data?.text.fr}
        </p>
        <div className="flex gap-10 justify-between items-center">
          <p class="text-4xl font-semibold dark:text-gray-100">
            ${data?.price}
          </p>
          <a
            aria-label="Start Now"
            target="_blank"
            rel="noreferrer"
            className="w-[200px] max-md:w-[188px]"
            href="https://wa.link/sc6cln"
          >
            <button
              type="button"
              class="py-[17.5px] flex items-center gap-2 justify-center font-sans max-md:py-4 max-md:text-sub3 select-none w-full font-semibold min-w-fit transition-[background-color] duration-150 disabled:cursor-not-allowed px-12 leading-none rounded-full bg-blue bg-[#224DE6] active:bg-[#1E44CC] text-white disabled:bg-[#E9EEFF] disabled:text-[#BCCAFF]"
            >
              {t("now")} &gt;
            </button>
          </a>
        </div>
      </div>
      {/* <div class="flex items-center">
        <div className="h-[350px] w-[400px] overflow-hidden rounded-lg">
          <img
            alt="1 on 1 mentorship"
            draggable="false"
            loading="lazy"
            decoding="async"
            data-nimg="1"
            class="object-cover max-md:w-[115px] select-none duration-150 transition-[opacity]"
            src={data?.image}
          />
        </div>
        <div class="px-4 pb-8 pt-7 space-y-[0.375rem]">
          <h3 class="text-sub1 font-medium capitalize md:hidden">
            {lang === "en" ? data?.en.titre : data?.titre}
          </h3>
          <p class="text-sub2 font-semibold md:hidden">${data?.price}</p>
        </div>
      </div> */}
      {/* <div class="flex flex-col gap-10 max-md:gap-4">
        <div class="space-y-3">
          <h3 class="text-3xl font-medium capitalize max-md:hidden">
            {lang === "en" ? data?.en.titre : data?.titre}
          </h3>
          <p class="max-w-[670px] text-sub3">
            {lang === "en" ? data?.en.text : data?.text}
          </p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-headline font-semibold text-4xl max-md:hidden">
            ${data?.price}
          </p>
          <a
            aria-label="Start Now"
            class="w-[200px] max-md:w-[188px]"
            href="/checkout/mentorship/1-on-1-mentorship?previous=/consultation"
          >
            <button
              type="button"
              class="py-[17.5px] flex items-center gap-2 justify-center font-sans max-md:py-4 max-md:text-sub3 select-none w-full font-semibold min-w-fit transition-[background-color] duration-150 disabled:cursor-not-allowed px-12 leading-none rounded-full bg-blue bg-[#224DE6] active:bg-[#1E44CC] text-white disabled:bg-[#E9EEFF] disabled:text-[#BCCAFF]"
            >
              Start Now &gt;
            </button>
          </a>
        </div>
        <div class="flex items-center justify-between md:hidden">
          <div class="flex items-center gap-1 text-cap1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#081228"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M2.872 11.598c-1.073-3.35.18-7.179 3.698-8.312a6.007 6.007 0 015.43.912c1.455-1.125 3.572-1.505 5.42-.912 3.517 1.133 4.779 4.962 3.707 8.312-1.67 5.31-9.127 9.4-9.127 9.4s-7.402-4.028-9.128-9.4z"
                clip-rule="evenodd"
              ></path>
              <path
                stroke="#081228"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M16 6.7a2.781 2.781 0 011.917 2.422"
              ></path>
            </svg>{" "}
            Wishlist
          </div>
          <a
            aria-label="Learn More"
            class="text-cap1 font-semibold underline-offset-4 focus:underline active:underline"
            href="/mentorships/1-on-1-mentorship"
          >
            Learn More &gt;
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Mentorship;
