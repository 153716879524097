import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isJwtExpired } from "jwt-check-expiration";
import { useNavigate } from "react-router-dom";
import { deconnexion } from "../slices/userSlice";

const Checksession = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const timer = setInterval(() => {
      if (auth.access) {
        if (isJwtExpired(auth.access)) {
          localStorage.removeItem("user");
          dispatch(deconnexion());
          navigate("/connexion");
        }
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [navigate, dispatch, auth]);

  return <>{props.children}</>;
};

export default Checksession;
