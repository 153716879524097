import React from "react";
import { BsMoonStars } from "react-icons/bs";
import { HiComputerDesktop } from "react-icons/hi2";
import { MdOutlineLightMode } from "react-icons/md";
import { useTheme } from "../hooks/ThemeContext";

const options = [
  {
    component: <MdOutlineLightMode />,
    text: "light",
  },
  {
    component: <BsMoonStars />,
    text: "dark",
  },
  {
    component: <HiComputerDesktop />,
    text: "system",
  },
];

const ShadeMode = () => {
  const { theme, setTheme } = useTheme();
  return (
    <div className="hs-dropdown relative inline-flex]">
      <button
        id="hs-dropdown-basic"
        type="button"
        className="hs-dropdown-toggle py-2.5 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      >
        {options.map((el) => {
          if (theme === el.text) {
            return <span key={el.text}>{el.component}</span>;
          } else {
            return null;
          }
        })}
      </button>

      <div
        className="hs-dropdown-menu [--placement:top-left transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 w-56 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-lg shadow-black/10 dark:shadow-none rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
        aria-labelledby="hs-dropdown-basic"
      >
        {options.map((el, key) => (
          <span
            onClick={() => setTheme(el.text)}
            key={key}
            className={`flex cursor-pointer items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700`}
          >
            <span>{el.component}</span>
            <span
              className={`${theme === el.text && "text-[#fbcd13]"}`}
            >
              {el.text}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default ShadeMode;
