import React, { useState } from "react";
import { useSignUpMutation } from "../api/authApi";
import { Link, useNavigate } from "react-router-dom";
import con from "../assets/19.svg";
import { color } from "../static/static";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../slices/userSlice";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { IoMdEye, IoIosEyeOff } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';
// import { useRegisterMutation } from "../API/authApi";

const Inscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp] = useSignUpMutation();

  const [eye, seteye] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    motdepass: "",
    nom: "",
    phone: "",
    status: "",
    // image: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();


    const res = await signUp({data: formData});
    if (res.data) {
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch(loginSuccess(res.data));
      navigate("/");
    } else {
      toast.error("Invalid data for register");
    }
  };

  return (
    <div className="flex justify-center h-full gap-x-20 items-center">
      <div className="flex flex-col min-w-[80%] lg:min-w-[430px] rounded-lg ">
        <h1 className="text-3xl text-center md:text-left font-medium mb-10 dark:text-white">
          Inscription
        </h1>
        <form
          action=""
          className="w-full flex flex-col gap-y-3"
          onSubmit={handleSubmit}
        >
          <div className="flex w-full">
            <input
              className="flex dark:text-white/80 w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm dark:bg-gray-500/50 focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
              required
              placeholder={t("formsMessages", { returnObjects: true }).nom}
              type="text"
              value={formData.nom}
              onChange={(e) =>
                setFormData({ ...formData, nom: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex w-full">
            {/* <input
              className="flex dark:text-white/80 w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm dark:bg-gray-500/50 focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
              required
              placeholder={t("formsMessages", { returnObjects: true }).phone}
              type="number"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.currentTarget.value })
              }
            /> */}
            <PhoneInput
              isValid={(inputNumber, country, countries) => {
                return countries.some((country) => {
                  return (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  );
                });
              }}
              country={"us"}
              autoFormat={true}
              containerStyle={{
                width: "100%",
                display: "flex",
                height: "60px",
              }}
              inputStyle={{
                width: "100%",
                display: "flex",
                height: "100%",
              }}
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e })}
            />
          </div>
          {/* <label className="block">
            <span className="sr-only">Choose profile photo</span>
            <input
              type="file"
              required
              placeholder="choisir une photo de profile"
              onChange={(e) => {
                setFormData((state) => {
                  return { ...state, image: e.target.files[0] };
                });
              }}
              className={`block w-full text-sm text-gray-500
              border border-gray-300 rounded
      file:me-4 file:py-4 file:px-4
      file:rounded file:border-0
      file:text-md
      file:bg-[${color.orange}] file:text-black file:font-medium
      file:disabled:opacity-50 file:disabled:pointer-events-none
      dark:file:bg-[#FBCD13] dark:border-none
      dark:hover:file:bg-[#FBCD13]/90 duration-150`}
            />
          </label> */}
          <div className="flex w-full">
            <input
              className="flex dark:text-white/80 w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm dark:bg-gray-500/50 focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
              required
              placeholder={t("formsMessages", { returnObjects: true }).email}
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.currentTarget.value })
              }
            />
          </div>
          <div className="flex w-full dark:text-white/80 dark:bg-gray-500/50 relative">
            <input
              className="flex w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm bg-transparent focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
              required
              placeholder={t("formsMessages", { returnObjects: true }).mdp}
              type={eye ? "text" : "password"}
              value={formData.motdepass}
              onChange={(e) =>
                setFormData({ ...formData, motdepass: e.currentTarget.value })
              }
            />
            <span
              className="text-2xl absolute right-3.5 top-[30%] z-10 cursor-pointer"
              onClick={() => seteye(!eye)}
            >
              {eye ? <IoMdEye /> : <IoIosEyeOff />}
            </span>
          </div>
          <button
            type="submit"
            className={`${color.orange} rounded text-black text-md font-medium py-4`}
          >
            {t("conPager", { returnObjects: true }).button}
          </button>
        </form>
        <p className="mt-5 text-sm dark:text-gray-400 text-center md:text-left">
          {t("inscPage", { returnObjects: true }).enMessage1}{" "}
          {typeof t("inscPage", { returnObjects: true }) === "object" &&
            t("inscPage", { returnObjects: true })
              .enMessage2.split(" ")
              .map((el, key) => {
                if (key === 0) {
                  return (
                    <Link to={"/connexion"} className={`text-[#FBCD13]`}>
                      {el}
                    </Link>
                  );
                } else {
                  return ` ${el}.`;
                }
              })}
        </p>
      </div>
      <div className="basis-[36%] hidden lg:block">
        <div>
          <img src={con} alt="" />
        </div>
        <p className="text-sm text-center dark:text-gray-400">
          {t("inscPage", { returnObjects: true }).message}
        </p>
      </div>
    </div>
  );
};

export default Inscription;
