import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AuthenticationApi = createApi({
  reducerPath: "AuthenticationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ( data ) => {
        return {
          url: "/user/login/",
          body: data,
          method: "POST",
        };
      },
    }),
    signUp: builder.mutation({
      query: ({data}) => {
        return {
          url: "/user/register",
          body: data,
          method: "POST",
        //   headers: {
        //     "Content-type": "multipart/form-data",
        //   },
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: "/user/logout",
          method: "POST",
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ uid, token, data }) => {
        return {
          url: `reset_password_confirm/${uid}/${token}/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useResetPasswordMutation,
  useLogoutMutation
} = AuthenticationApi;
