import React from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const OverviewCard1 = ({ data }) => {
  return (
    <div className="border border-gray-100 shadow-xl shadow-gray-200/30 flex flex-col divide-y divide-gray-100 rounded-xl">
      <div className="p-6 px-10 flex gap-x-6 items-start">
        <img src={data?.icon} alt="icon" className="w-14"/>
        <div className="flex flex-col gap-y-1">
          <h3 className="text-gray-600 font-light text-sm">{data?.label}</h3>
          <span className="text-4xl font-semibold text-gray-800">{data?.value}</span>
        </div>
      </div>
      <div></div>
      <p className="p-2.5 self-start">
        <Link className="flex items-center gap-x-2 px-4 py-1.5 duration-200 hover:bg-gray-100/70 rounded-lg">
          <span className="text-sm font-semibold text-gray-600">{data?.next}</span>
          <span>
            <FiArrowRight />
          </span>
        </Link>
      </p>
    </div>
  );
};

export default OverviewCard1;
