import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useGetOneUserQuery } from "../../api/userApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import InputOwn from "../../components/InputOwn";
import { color } from "../../static/static";

const EditUsers = () => {
  const id = useParams().id;
  const navigate = useNavigate()
  const { data, isSuccess } = useGetOneUserQuery({ id: id });

  const [nom, setNom] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [date_naissance, setDateNaissance] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setNom(data?.nom);
      setPhone(data?.phone);
      setImage(data?.image);
      setEmail(data?.email);
      setDateNaissance(data?.date_naissance);
    }
  }, [data, navigate, isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col gap-y-10">
      <Link to="/dashboard/utilisateur" className="flex items-center gap-x-2">
        <FiArrowLeft className="text-lg" />
        <span className="text">Utilisateurs</span>
      </Link>
      {isSuccess && (
        <div className="flex gap-x-4 items-center">
          <div>
            <Avatar
              sx={{ width: "60px", height: "60px" }}
              src={`${data.image}`}
            />
          </div>
          <div>
            <h2 className="text-3xl font-semibold">{data.email}</h2>
            <h2 className="text-sm mt-2">
              <span className="font-medium">user_id:</span>{" "}
              <span className="bg-gray-200/70 py-1 px-3 rounded-full">
                {data._id}
              </span>
            </h2>
          </div>
        </div>
      )}
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">Editer les informations</h3>
        <div className="mt-5">
          <form action="">
            {isSuccess && (
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Username"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <InputOwn
                  label="email"
                  type="email"
                  value={email}
                  modifier={setEmail}
                />
                <InputOwn
                  label="Numéro de téléphone"
                  type="number"
                  value={phone}
                  modifier={setPhone}
                />
                <InputOwn
                  label="Date de naissance"
                  type="date"
                  value={date_naissance}
                  modifier={setDateNaissance}
                />
                <InputOwn
                  label="Photo de profil"
                  type="file"
                  value={image}
                  Hstyle="col-span-2"
                  modifier={setImage}
                />
              </div>
            )}
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Modifier
              </button>
              <button
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUsers;
