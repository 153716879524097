import { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext({
  theme: "default",
  darkQuery: {},
  setTheme: () => {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme"))
      : "system"
  );
  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const element = document.documentElement;

  const onWindowMatch = () => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && darkQuery.matches)
    ) {
      element.classList.add("dark");
    } else {
      element.classList.remove("dark");
    }
  };

  useEffect(() => {
    switch (theme) {
      case "dark":
        element.classList.add("dark");
        localStorage.setItem("theme", JSON.stringify("dark"));
        break;
      case "light":
        element.classList.remove("dark");
        localStorage.setItem("theme", JSON.stringify("light"));
        break;
      default:
        localStorage.removeItem("theme");
        onWindowMatch();
        break;
    }
  });

  return (
    <ThemeContext.Provider
      value={{
        theme,
        darkQuery,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
