import React from "react";
import toast from "react-hot-toast";
import { MdOndemandVideo } from "react-icons/md";

const Session = ({ data, open, acheter }) => {
  return (
    <div
      onClick={acheter ? open : () => {}}
      className="flex justify-between items-center py-3 cursor-pointer"
    >
      <div className="flex items-center gap-x-3">
        <MdOndemandVideo className="dark:text-[#FCD514]" />
        <span className="text-gray-600 hover:underline dark:text-gray-400">
          {data.title}
        </span>
      </div>
      <span className="text-sm text-gray-600 dark:text-gray-400">
        {data.duree} min
      </span>
    </div>
  );
};

export default Session;
