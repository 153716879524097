import { payment } from "../static/Payment";
import { useState } from "react";
import { RandomGenerator } from "@hachther/mesomb-browser";
import toast from "react-hot-toast";

const usePayment = () => {
  const [response, setresponse] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [isError, setisError] = useState(false);

  const buyFormation = async (custom, number, price, service) => {
    const paymentData = {
      customer: { id: custom },
      amount: price,
      service: service,
      payer: number.toString().replace("+237", ""),
      nonce: RandomGenerator.nonce(),
    };
    setisLoading(true);
    payment
      .makeCollect({
        ...paymentData,
      })
      .then((res) => {
        console.log(res);
        setisLoading(false);
        setisSuccess(true);
        setresponse(res);
        toast.success("Payement effectuer avec succès");
      })
      .catch((err) => {
        toast.error(err.message);
        setisError(err.message);
        setisLoading(false);
        console.log(err);
      });

    // const pay = payment
    //   .makeCollect({
    //     amount: 100,
    //     service: "MTN",
    //     payer: "683350860",
    //     nonce: RandomGenerator.nonce(),
    //   })
    // //  const test = await payment.checkTransactions(pay)
    // console.log(pay);
  };
  return { buyFormation, isError, isLoading, isSuccess, response };
};

export default usePayment;
