import React, { useEffect, useState } from "react";
import InputOwn from "../../components/InputOwn";
import { color } from "../../static/static";
import BodyHead from "../../components/BodyHead";
import { useGetAllAcademiQuery } from "../../api/AcademiApi";
import {
  useCreateModuleMutation,
  useEditModuleMutation,
  useGetAllModulesQuery,
  useLazyGetOneModuleQuery,
} from "../../api/ModuleApi";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

const CreateModuleForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading, isSuccess } = useGetAllAcademiQuery();
  // const { data: module, refetch: moduleRf } = useGetOneModuleQuery({ id: id });
  const [
    getOneModule,
    { data: module, isLoading: load },
  ] = useLazyGetOneModuleQuery();

  const [createModule] = useCreateModuleMutation();
  const [editModule] = useEditModuleMutation();
  const { refetch } = useGetAllModulesQuery();

  const [nom, setNom] = useState("");
  const [academic, setAcademie] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = null;
    if (id) {
      res = await editModule({ id: id, data: { nom, description, academic } });
    } else {
      res = await createModule({
        data: { nom, description, academic },
      });
    }
    if (res.data) {
      if (id) {
        toast.success("Edited succefully");
      } else {
        toast.success("Created succefully");
      }
      refetch();
    } else {
      toast.error("Failed to create module");
    }
  };

  useEffect(() => {
    if (id) {
      setNom(module?.nom);
      setAcademie(module?.academic);
      setDescription(module?.description);
    }
  }, [module, id]);

  useEffect(() => {
    if (id) {
      getOneModule({ id: id });
    }
  }, [navigate, id, getOneModule]);

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title={id ? "Editer un module" : "Créer un module"} />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">
          {id ? "Editer un module" : "Créer un module"}
        </h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Nom du module"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <div className="relative inline-block">
                  <select
                    defaultValue="vip-1"
                    className="block appearance-none w-full text-gray-500 text-sm bg-white border border-gray-200 hover:border-gray-500 px-4 py-4 active:right-2 pr-8 rounded-lg leading-tight focus:outline-blue-600 focus:border-blue-500"
                    value={academic}
                    onChange={(e) => {
                      setAcademie(e.target.value);
                    }}
                    placeholder="Choisisez une académie"
                  >
                    <option value="" disabled selected>
                      Sélectionnez l'academie à laquelle vous souhaitez relier
                      le module
                    </option>
                    {!isLoading &&
                      isSuccess &&
                      data.map((el, key) => (
                        <option key={key} value={el._id}>
                          {el.nom}
                        </option>
                      ))}
                  </select>
                  <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
                    <svg
                      className="flex-shrink-0 w-4 h-4 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <InputOwn
                  Hstyle={"col-span-2"}
                  children={
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Descripion du module"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                    ></textarea>
                  }
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                {load && <CircularProgress color="inherit" size={20}/>}
                {!load && (id ? "Editer" : "Valider")}
              </button>
              <button
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateModuleForm;
