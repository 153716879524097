import React from "react";
import { FiSearch } from "react-icons/fi";
import DataGrid from "../../../components/DataGrid";
import ContenueLine from "../../../components/TableLignes/ContenueLine";
import { useGetAllContenueQuery } from "../../../api/contenuApi";

const contenues = ["Titre", "image", "Textes", "Actions"];

const HomeContent = () => {
  const { data: contenu, isSuccess, refetch } = useGetAllContenueQuery();
  return (
    <div className="my-10 mb-16 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col divide-y divide-gray-200">
      <div className="px-6 py-4">
        <ul className="flex gap-x-5 text-sm text-gray-500">
          <li>Tout</li>
          <li>Ayant souscrit</li>
          <li>Client</li>
          <li>Prospect</li>
        </ul>
      </div>
      <div className="h-full">
        <div className="p-6 flex gap-x-6">
          <div className="relative w-full">
            <input
              type="search"
              className="peer border border-gray-200 py-4 px-4 ps-11 focus:outline-[#6366F1] block w-full rounded-lg text-sm focus:border-[#6366F1] focus:ring-[#6366F1] disabled:opacity-50 disabled:pointer-events-none"
              placeholder="Enter name"
            />
            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4 peer-disabled:opacity-50 text-2xl text-gray-500 peer-disabled:pointer-events-none">
              <FiSearch />
            </div>
          </div>
          <div className="relative">
            <select
              defaultValue=""
              className="peer p-4 pe-9 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
focus:pt-6
focus:pb-2
[&:not(:placeholder-shown)]:pt-6
[&:not(:placeholder-shown)]:pb-2
autofill:pt-16
autofill:pb-2"
            >
              <option defaultValue>Filtrer le tableau</option>
              <option>Date d'inscription</option>
              <option>Nom</option>
              <option>Prenom</option>
            </select>
            <label
              className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
peer-focus:text-xs
peer-focus:-translate-y-1.5
peer-focus:text-gray-500
peer-[:not(:placeholder-shown)]:text-xs
peer-[:not(:placeholder-shown)]:-translate-y-1.5
peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              Filtre
            </label>
          </div>
        </div>
        <div className="">
          {isSuccess && (
            <DataGrid
              header={contenues}
              Children={contenu.map((el, key) => (
                <ContenueLine data={el} key={key} refetch={refetch} />
              ))}
            ></DataGrid>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
