import React from "react";
import Statistic from "../../components/Statistic";
import Imgcard from "../../components/Imgcard";
import Card from "../../components/Card";
import Action from "../../components/Action";
import Carousel from "../../components/Carousel";
import "./home.css";
import { useTranslation } from "react-i18next";
import { useGetAllAcademiQuery } from "../../api/AcademiApi";
import { useGetAllContenueQuery, useGetHome1Query } from "../../api/contenuApi";
import useNews from "../../hooks/useNews";
// import useGetFormation from "../../hooks/useGetFormation";
import { useGetAllFormationsQuery } from "../../api/formationApi";
import Editable from "../../components/Editable";
import Mentorship from "../../components/Mentorship";
import vip1 from "../../assets/vip 1.png";
import vip2 from "../../assets/vip 2.png";
import vip3 from "../../assets/vip 3.png";

const Home = () => {
  const lang = localStorage.getItem("i18nextLng");
  const vips = [vip1, vip2, vip3];
  const { data: contenu, isSuccess: contSuc } = useGetAllContenueQuery();
  const { data: academie, isSuccess: acadSuccess } = useGetAllAcademiQuery();
  const { news, newsSuccess } = useNews();
  const { data, isSuccess } = useGetAllFormationsQuery();
  const { data: home, isSuccess: homeSuccess } = useGetHome1Query();

  const { t } = useTranslation();
  const Nouvelle = t("NewsBlock", { returnObjects: true });

  return (
    <>
      {/* ----------------Carousel---------------- */}
      {contSuc && <Carousel data={contenu} />}
      {/* ----------------Formations---------------- */}
      <div className="dark:bg-gray-900 bg-white px-[6.5%] pt-16 pb-20">
        <div className="flex flex-col items-center">
          {isSuccess && (
            <Statistic
              main={lang === "en" ? data[0].en.titres[0] : data[0].titres[0]}
              text={lang === "en" ? data[0].en.titres[0] : data[0].titres[0]}
              dark={"dark:text-white"}
              light={"text-center"}
              stitle={"text-[38px] font-medium"}
              sp={"text-xl text-gray-500 mb-10 mt-1"}
            />
          )}
          <div className="flex justify-center w-9/12 max-[1050px]:w-full rounded-lg mt-8">
            <iframe
              className="w-[900px]"
              title="start video"
              height="460"
              frameBorder="0"
              src="https://mega.nz/embed/QqtyhChK#c6DjDeRTDkkSW547-IjoiqppZ-FjdOlRBsw8MfzQ6B0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      {/* ----------------First block---------------- */}
      <div
        id="firstblock"
        className="dark:bg-gray-900 bg-white px-[6.5%] py-20"
      >
        <div className="flex flex-col gap-y-8">
          {isSuccess && (
            <Statistic
              main={lang === "en" ? data[0].en.titres[1] : data[0].titres[1]}
              text={
                lang === "en"
                  ? data[0].en.descriptions[1]
                  : data[0].descriptions[1]
              }
              dark={"dark:text-white text-gray-900 mb-5"}
              light={"text-center"}
              stitle={"text-[38px] font-medium "}
              sp={"text-xl text-gray-500 mt-1"}
            />
          )}
          <div className="flex justify-center gap-4 cap items-center flex-wrap">
            <div className="flex flex-col lg:flex-row gap-10 mt-5">
              {acadSuccess &&
                academie?.map((element, id) => (
                  <Imgcard
                    vip={vips[id]}
                    key={id}
                    src={element.video}
                    main={lang === "en" ? element.en.nom : element.nom}
                    text={
                      lang === "en"
                        ? element.en.description
                        : element.description
                    }
                    el={element}
                    light=""
                    dark="dark:text-white text-gray-900"
                    stitle="text-[22px] font-semibold first-letter:capitalize mb-2 text-center lg:text-left"
                    sp="max-w-[500px] text-gray-400 text-center lg:text-left first-letter:uppercase"
                  />
                ))}
            </div>
            {/* <img
              src={im7}
              alt=""
              className="object-cover w-[450px] mr-16 hidden md:block"
            /> */}
          </div>
        </div>
      </div>
      {/* ----------------News block---------------- */}
      <div className="px-[6.5%] py-14 dark:bg-gray-900">
        <Editable />
      </div>
      <div className="px-[6.5%] w-full py-14 dark:bg-slate-900/95 bg-white flex items-center">
        {homeSuccess && <Mentorship data={home[0]} />}
      </div>
      <div className="px-[6.5%] w-full py-14 dark:bg-slate-900/95 bg-white">
        <Statistic
          main={Nouvelle?.title}
          text={null}
          dark={"dark:text-white"}
          light={""}
          stitle={"text-[48px] text-dark font-medium text-center"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 my-20">
          {newsSuccess &&
            news.slice(0, 8).map((element, id) => {
              return (
                <Card
                  url={element.url}
                  key={id}
                  src={element.urlToImage}
                  main={element.title}
                  text={element.description}
                  dark="dark:text-white dark:shadow-none dark:bg-gray-700/50"
                  light="p-5 bg-white hover:shadow-lg shadow-gray-200/70 basis-[40%] h-full"
                  stitle="font-semibold text-lg mb-2"
                  sp="mb-2 text-gray-500 text-sm"
                />
              );
            })}
        </div>
      </div>
      {/* ----------------Ne perdez plus de temps---------------- */}
      <div className="px-[6.5%] w-full py-14 bg-slate-900/95 dark:bg-[url('bgback.jpg')] bg-no-repeat bg-cover">
        <Action
          st="flex text-gray-100 flex-col gap-y-4 w-full text-center items-center"
          title={t("endMessage", { returnObjects: true }).title}
          path="https://wa.link/sc6cln"
          text={t("endMessage", { returnObjects: true }).bouton}
        />
      </div>
    </>
  );
};

export default Home;
