import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const NewsApi = createApi({
  reducerPath: "NewsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
        headers.set("Content-type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllNews: builder.query({
      query: ({lang}) => `/news/${lang}/`,
    }),
  }),
});

export const {
  useGetAllNewsQuery
} = NewsApi;
