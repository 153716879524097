import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ContenuApi = createApi({
  reducerPath: "ContenuApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllContenue: builder.query({
      query: () => "/contenu/",
    }),
    getHome1: builder.query({
      query: () => "/home/",
    }),
    getAllExchange: builder.query({
      query: () => "/contenu/exchange",
    }),
    getOneContenu: builder.query({
      query: (id) => `/contenu/${id}`,
    }),
    createContent: builder.mutation({
      query: ({ data }) => {
        return {
          url: "/contenu/add/",
          method: "POST",
          body: data,
        };
      },
    }),
    deleteContent: builder.mutation({
      query: ( id ) => {
        return {
          url: `/contenu/delete/${id}`,
          method: "DELETE",
        };
      },
    }),
    updateContent: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/contenu/update/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updatePub: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/home/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    updateExchangeCt: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/contenu/exchange/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAllContenueQuery,
  useCreateContentMutation,
  useLazyGetOneContenuQuery,
  useDeleteContentMutation,
  useUpdateContentMutation,
  useGetHome1Query,
  useGetAllExchangeQuery,
  useUpdateExchangeCtMutation,
  useUpdatePubMutation
} = ContenuApi;
