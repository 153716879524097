import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const FormateurApi = createApi({
  reducerPath: "FormateurApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllformateurs: builder.query({
      query: () => "/formateur/",
    }),
    createformateur: builder.mutation({
      query: ({ data }) => {
        return {
          url: "/formateur",
          method: "POST",
          body: data,
          
        };
      },
    }),
    getOneformateur: builder.query({
      query: ({ id }) => {
        return {
          url: `/formateur/${id}`,
        };
      },
    }),
    getListformateur: builder.query({
      query: ({ id }) => {
        return {
          url: `/formateur/formAc/${id}`,
        };
      },
    }),
    editformateur: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/formateur/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteformateur: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/formateur/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllformateursQuery,
  useDeleteformateurMutation,
  useCreateformateurMutation,
  useLazyGetOneformateurQuery,
  useGetListformateurQuery,
  useEditformateurMutation
} = FormateurApi;
