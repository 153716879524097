import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const AcademiApi = createApi({
  reducerPath: "AcademiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
        headers.set("Content-type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllAcademi: builder.query({
      query: () => "/academic/",
    }),
    getOneAcademi: builder.query({
      query: ({ id }) => `/academic/${id}`,
    }),
    createAcademi: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/academic/add/${id}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getListAcademi: builder.query({
      query: () => {
        return {
          url: `/academic/listAcademic`,
        };
      },
    }),
    getGroupAcademi: builder.query({
      query: ({ id }) => {
        return {
          url: `/academic/academie/${id}`,
        };
      },
    }),
    deleteAcademi: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/academic/delete/${id}`,
          method: "DELETE",
        };
      },
    }),
    editAcademi: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/academic/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAllAcademiQuery,
  useCreateAcademiMutation,
  useDeleteAcademiMutation,
  useEditAcademiMutation,
  useGetOneAcademiQuery,
  useGetListAcademiQuery,
  useGetGroupAcademiQuery,
} = AcademiApi;
