import React from "react";

const InputOwn = ({
  disabled,
  value,
  modifier,
  label,
  placeholder,
  type,
  children,
  Hstyle,
  or,
}) => {
  return (
    <div className={`relative ${Hstyle}`}>
      {!children && (
        <div className="relative">
          {type !== "file" && (
            <input
              disabled={disabled}
              value={value}
              type={type}
              id="hs-floating-input-email-value"
              className={`peer px-5 py-5 border-gray1200  backdrop-blur-sm ${
                or &&
                "dark:bg-white/20 dark:placeholder:text-white disabled:text-white dark:border-gray-500 dark:focus:outline-[#FCD514] dark:focus:ring-[##FCD514] text-white"
              } placeholder:text-transparent border border-gray-200  focus:outline-[#6366F1] block w-full rounded-lg text-sm focus:border-[#6366F1] focus:ring-[#6366F1] disabled:opacity-50 disabled:pointer-events-none 
              focus:pt-6
              focus:pb-2
              [&:not(:placeholder-shown)]:pt-6
              [&:not(:placeholder-shown)]:pb-2
              autofill:pt-6
              autofill:pb-2`}
              placeholder={placeholder}
              onChange={(e) => modifier(e.target.value)}
            />
          )}
          {type !== "file" && (
            <label
              htmlFor="hs-floating-input-email-value"
              className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              {label}
            </label>
          )}
          {type === "file" && (
            <>
              <label htmlFor="file-input-medium" className="sr-only">
                Choose file
              </label>
              <input
                disabled={disabled}
                onChange={(e) => modifier(e.target.files[0])}
                type="file"
                name="file-input-medium"
                id="file-input-medium"
                className={`block w-full border border-gray-200 ${
                  or &&
                  "dark:border-gray-500 dark:file:bg-[#FCD514] dark:file:text-black"
                }  shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:border-0
                file:bg-[#6366F1] file:text-white file:me-4
                file:py-4 file:px-4`}
              />
            </>
          )}
        </div>
      )}
      {children && children}
    </div>
  );
};

export default InputOwn;
