import React, { useEffect, useState } from "react";
import InputOwn from "../../../components/InputOwn";
import { color } from "../../../static/static";
import { IconButton } from "@mui/material";
import { MdPhotoCamera } from "react-icons/md";
import dummyProfile from "../../../assets/dummyProfilePic.png";
import {
  useGetAllActualiteQuery,
  useUpdateActualiteMutation,
} from "../../../api/actualiteApi";
import toast from "react-hot-toast";

const Actualite = () => {
  const [realIamage, setEdit1] = useState(false);
  const [titre, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");

  const [updateActu] = useUpdateActualiteMutation();
  const { data, isSuccess: datS } = useGetAllActualiteQuery();

  const handleChoose = (e) => {
    if (e.target.files[0]) {
      setEdit1(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        setImage(reader.result);
      };
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", realIamage);
    formData.append("titre", titre);
    formData.append("text", text);

    const res = await updateActu({ id: data[0]._id, data: formData });
    if (res.data) {
      toast.success("Mis à jour avec succès");
    } else {
      toast.error("Une erreur est survenus");
    }
  };

  useEffect(() => {
    if (datS) {
      setTitle((state) => {
        return data[0].titre.fr;
      });
      setText((state) => {
        return data[0].text.fr;
      });
      setImage((state) => {
        return data[0].image;
      });
    }
  }, [datS, data]);
  return (
    <div className="mb-16">
      {
        <div className="flex flex-col gap-y-10">
          <div className="my-4 mb-16 p-6 h-full rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
            <div className="mt-5">
              <form action="" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-5">
                  <InputOwn
                    Hstyle={"col-span-2"}
                    label="Titre du contenu"
                    type="text"
                    value={titre}
                    modifier={setTitle}
                  />
                  <InputOwn
                    children={
                      <textarea
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Entrer le texte de description"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                        className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                      ></textarea>
                    }
                  />
                  <label
                    className="flex gap-3 border rounded-lg h-72 overflow-hidden relative cursor-pointer"
                    htmlFor="image"
                  >
                    <img
                      src={image || dummyProfile}
                      alt="profile"
                      className="object-cover w-full z-10"
                    />
                    <IconButton
                      variant="text"
                      sx={{
                        position: "absolute",
                        top: "45%",
                        left: "42%",
                        zIndex: 50,
                      }}
                      component="label"
                      disableElevation
                      color="primary"
                    >
                      <MdPhotoCamera className="text-4xl" />
                    </IconButton>
                    <input
                      id="image"
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleChoose}
                    />
                  </label>
                </div>
                <div className="flex gap-x-4 mt-10">
                  <button
                    type="submit"
                    className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
                  >
                    Modifier
                  </button>
                  <span
                    onClick={() => setEdit1(false)}
                    className={`hover:bg-gray-100 cursor-pointer text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
                  >
                    Annuler
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Actualite;
