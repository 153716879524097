import React from "react";
import { LuDot } from "react-icons/lu";
import { Link, useLocation, useParams } from "react-router-dom";

const NavigationState = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigation = pathname.split("/");
  navigation.shift();
  if(id){
    navigation.pop()
  }
  return (
    <div className="flex items-center">
      {navigation.map((el, key) => (
        <Link
          key={key}
          to={
            key === 0
              ? `/${el}`
              : key === navigation.length - 1
              ? ""
              : `/dashboard/${el}`
          }
          className={`text-sm capitalize flex items-center ${
            key === 0
              ? `font-medium hover:underline`
              : key === navigation.length - 1
              ? "text-gray-400 cursor-default"
              : "hover:underline font-medium"
          }`}
        >
          <span className="text-2xl font-bold">{key !== 0 && <LuDot />}</span>
          {el}
        </Link>
      ))}
    </div>
  );
};

export default NavigationState;
