import React, { useEffect, useMemo, useState } from "react";
import { useGetAllNewsQuery } from "../api/newsApi";
import { Skeleton } from "@mui/material";
import { FaAngleDoubleRight } from "react-icons/fa";
import Skel from "../components/Skel";
import SkelR from "../components/SkelR";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useNews from "../hooks/useNews";

const News = () => {
  const { news, newsSuccess } = useNews();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [element, setelement] = useState(8);
  const { isLoading, refetch } = useGetAllNewsQuery({
    lang: lang,
  });
  const random = useMemo(() => Math.ceil(Math.random() * 10), []);

  const setMoreItems = () => {
    setelement((state) => state + 5);
  };

  const convertTime = (dateString) => {
    const dateTime = new Date(dateString);
    const heure = dateTime.toLocaleTimeString();
    const date = dateTime.toLocaleDateString();
    return date + " " + heure;
  };

  useEffect(() => {
    refetch();
  }, [lang, refetch]);

  return (
    <div className="px-[6.5%] w-full py-10 dark:dark:bg-slate-900/95 bg-gray-100/70">
      <div className="flex gap-x-8 relative">
        <div className="basis-[100%] lg:basis-[75%]">
          {newsSuccess && (
            <div className="flex flex-col md:flex-row gap-x-10 border-b border-gray-300 dark:border-gray-600 pb-5">
              <div className="w-full md:w-[300px] h-[180px] overflow-hidden flex-none">
                <img
                  src={news[random].urlToImage}
                  alt=""
                  className="bg-cover"
                />
              </div>
              <div className="mt-4 md :mt-0 flex flex-col">
                <h1 className="mb-5 text-4xl font-semibold dark:text-white">
                  {news[random]?.title?.slice(0, 25)}...
                </h1>
                <p className="text-gray-600 text-sm dark:text-gray-400">
                  {news[random]?.description?.slice(0, 525)}...
                </p>
              </div>
            </div>
          )}
          {!newsSuccess && (
            <div className="hidden lg:flex gap-x-10 border-b border-gray-300 pb-5 w-full">
              <div className="lg:w-[300px] lg:h-[180px] overflow-hidden flex-none">
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"100%"}
                />
              </div>
              <div className="hidden lg:block w-full">
                <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  height={100}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-5 mt-8 relative">
            {newsSuccess &&
              news.slice(0, element).map((el) => {
                return (
                  <div className="flex flex-col lg:flex-row bg-white dark:bg-slate-900 rounded h-auto lg:h-[240px]">
                    {el.urlToImage ? (
                      <div className="w-full lg:w-[300px] flex h-full overflow-hidden flex-none">
                        <img
                          src={el.urlToImage}
                          className="object-cover w-full"
                          alt={el.title}
                        />
                      </div>
                    ) : null}
                    <div className="p-7 flex flex-col gap-y-2">
                      <h2 className="text-xl font-semibold dark:text-white">
                        {el.title}
                      </h2>
                      <span className="dark:text-white/20">{el.author}</span>
                      <p className="text-sm text-neutral-700 dark:text-gray-400">
                        {el.description}
                      </p>
                      <div className="flex justify-between items-center mt-auto">
                        <span className="text-sm mt-2 text-gray-500 rounded-lg">
                          {convertTime(el.publishedAt)}
                        </span>
                        <Link
                          to={el.url}
                          className="flex items-center text-sm gap-x-1 text-gray-400 cursor-pointer"
                        >
                          more <FaAngleDoubleRight className="text-sm" />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            {isLoading && !newsSuccess && (
              <div className="hidden lg:flex flex-col gap-y-5 mt-8">
                <Skel />
                <Skel />
                <Skel />
                <Skel />
                <Skel />
                <Skel />
              </div>
            )}
            <div className="w-full bg-gradient-to-t  dark:from-slate-900/90 from-white h-[500px] absolute bottom-0"></div>
          </div>
          {newsSuccess && (
            <>
              {element <= news.length && (
                <div
                  className="mt-5 text-gray-400 dark:bg-slate-900/90 p-3 bg-gray-200/60 hover:bg-gray-200/80 duration-300 text-center cursor-pointer"
                  onClick={setMoreItems}
                >
                  {t("more")}
                </div>
              )}
            </>
          )}
        </div>
        <div className="hidden lg:basis-[25%] lg:flex flex-col gap-y-5 sticky top-0">
          {newsSuccess &&
            news.slice(random + 3, random + 7).map((el) => {
              return (
                <div className="hidden lg:flex flex-col bg-white dark:bg-slate-900 rounded">
                  {el.urlToImage ? (
                    <div className="w-full flex h-full">
                      <img
                        src={el.urlToImage}
                        className="bg-contain object-cover"
                        alt={el.title}
                      />
                    </div>
                  ) : null}
                  <div className="p-7 flex flex-col gap-y-2">
                    <h2 className="text-xl font-semibold dark:text-white">
                      {el.title}
                    </h2>
                    <span className="dark:text-white/40">{el.author}</span>
                    <div className="flex justify-between items-center mt-auto">
                      <span className="font-poppins text-sm mt-2 text-gray-500 rounded-lg">
                        {convertTime(el.publishedAt)}
                      </span>
                      <Link
                        to={el.url}
                        className="flex items-center gap-x-1 text-sm text-gray-400 cursor-pointer"
                      >
                        more <FaAngleDoubleRight className="text-sm" />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          {isLoading && !newsSuccess && (
            <div className="hidden lg:flex flex-col gap-y-4">
              <SkelR />
              <SkelR />
              <SkelR />
              <SkelR />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default News;
