import React from "react";
import { IoMdPlayCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
import ReactPlayer from "react-player";

const Modals = ({
  academi,
  activLink,
  setactivLink,
  setactiveTitle,
  activeTitle,
  open,
  close,
}) => {
  return (
    open && (
      <div className="absolute w-full h-screen top-0 bottom-0 bg-black/60 overflow-y-scroll py-40 flex justify-center items-start z-[100]">
        <div className="w-[680px] min-h-[800px] pb-6 bg-gray-100 rounded">
          <div className="p-6">
            <div className="flex justify-between w-full items-center">
              <h3>Aperçu du cours</h3>
              <div
                onClick={close}
                className="w-8 h-8 rounded-full bg-gray-200 flex justify-center cursor-pointer items-center"
              >
                <MdClose />
              </div>
            </div>
            <div className="mt-3 flex items-center gap-2">
              <h1 className="font-medium text-lg">{academi.nom} :</h1>
              <span className="first-letter:capitalize text-lg font-medium">
                {academi.title.toLowerCase()}
              </span>
            </div>
            <div className="mt-5">
              <ReactPlayer
                controls={true}
                width={"100%"}
                url={activLink || ""}
              />
            </div>
          </div>
          <div className="">
            <h1 className="text-lg px-6 font-medium">Liste des vidéos:</h1>
            <div>
              {academi.modules.map((el) => (
                <div>
                  <div className="border-b border-b-gray-200 text-gray-500 mx-6 py-1">
                    {el.nom}
                  </div>
                  <div className="flex flex-col gap-1">
                    {el.video.map((vid) => (
                      <div
                        onClick={() => {
                          setactivLink(vid.link);
                          setactiveTitle(vid.title);
                        }}
                        className={`${
                          activeTitle === vid.title ? "bg-gray-200/80" : ""
                        } px-6 py-4 flex gap-3 cursor-pointer items-center w-full`}
                      >
                        <div className="w-20 h-[50px] rounded bg-black/80 overflow-hidden">
                          <ReactPlayer
                            controls={true}
                            width={"100%"}
                            url={vid.link}
                          />
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <h1 className="text-sm font-medium flex items-center gap-2">
                            {activeTitle === vid.title && (
                              <IoMdPlayCircle className="text-lg" />
                            )}{" "}
                            {vid.title.slice(0, 40)}...
                          </h1>
                          <span className="text-sm font-medium">
                            {("0" + vid.duree).slice(-2)}:00
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modals;
