import React, { useEffect, useState } from "react";
import { HiLockClosed } from "react-icons/hi";
import { color } from "../static/static";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import usePayment from "../hooks/usePayment";
import { Navigate, useParams } from "react-router-dom";
import { useGetOneAcademiQuery } from "../api/AcademiApi";
import { CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useCreatePayMutation } from "../api/paiementApi";

const phoneNumberRegex = /^(\+237)?\s?[2368]\d{7,8}$/;

const converter = (amount) => {
  const tauxcfaeuro = 0.00152;
  const tauxeurodoll = 1.3203; // Exchange rate: 1 USD = 558.45 XAF
  const euroAmount = amount / tauxeurodoll;
  const convertedAmount = euroAmount / tauxcfaeuro;

  return Math.round(convertedAmount);
};

const ByeFormation = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.auth);
  const [createpay] = useCreatePayMutation();
  const {
    refetch,
    data: academi,
    isLoading: load,
    isError: error,
  } = useGetOneAcademiQuery({ id: id });
  console.log(academi);

  const [phone, setphone] = useState("");
  const [servie, setservie] = useState("");
  const { buyFormation, isLoading, isSuccess, response } = usePayment();

  const handlePay = () => {
    if (!phoneNumberRegex.test(phone)) {
      toast.error("Le numéroo de téléphone n'est pas correct");
      return;
    } else if (!servie) {
      toast.error("Le mode de paiement est obligatoire");
      return;
    } else {
      buyFormation(user.user._id, phone, converter(academi.prix), servie);
    }
  };

  const handlePhoneChange = (value, country) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.slice(countryCode.length); // Remove the country code from the input value
    const formattedPhone = `+${countryCode}${phoneNumber}`;
    setphone(formattedPhone);
  };

  useEffect(() => {
    async function pay() {
      const res = await createpay({
        data: {
          client: user.user._id,
          academic: id,
          transaction: response.transaction,
        },
      });
      if (!res.data) {
        toast.error("Une erreur s'est produite");
      } else {
        toast.success("Vous pouvez maintenant consulter la formation ");
        refetch();
      }
    }
    if (isSuccess) {
      pay();
    }
  }, [response, isSuccess, createpay, id, user]);

  if (load) {
    return (
      <div className="dark:bg-slate-900 relative h-full flex justify-center items-center">
        <CircularProgress color="inherit" />
      </div>
    );
  } else if (error) {
    return <Navigate to={"/*"} />;
  }
  return (
    <div className="px-[6.5%] h-full dark:bg-slate-900 py-16">
      <div className="flex-col xl:mt-0 lg:flex-row flex xl:justify-center gap-10 text-sm">
        <div className="basis-[50%] flex flex-col gap-y-10">
          <h1 className="text-4xl font-medium dark:text-white">Validation</h1>
          <form action="" className="flex flex-col gap-y-8">
            <div>
              <h2 className="text-2xl font-semibold mb-3 dark:text-white">
                Adresse de facturation
              </h2>
              <div className="flex justify-between items-center mb-2">
                <label htmlFor="" className="dark:text-white/70">
                  Pays
                </label>
                <span className="dark:text-white/70">Exigé</span>
              </div>

              <PhoneInput
                placeholder="+237 683 350 860"
                country="cm"
                disableDropdown
                inputStyle={{
                  width: "100%",
                  paddingTop: 25,
                  paddingBottom: 25,
                }}
                containerClass={{ width: "100%" }}
                onlyCountries={["cm"]}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                  maxLength: 16,
                }}
                value={phone}
                containerStyle={{ width: "100%" }}
                onChange={handlePhoneChange}
              />
              <p className="text-xs text-gray-500 mt-3 dark:text-gray-400">
                Comme exigé par la loi, Cryptocoq prélève des taxes sur les
                transactions aplicables sur achat réalisés dans certaines
                juridicions fiscales.
              </p>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold mb-3 dark:text-white">
                  Mode de paiement
                </h1>
                <span className="flex items-center gap-x-2 dark:text-white/80">
                  Connexion sécurisée{" "}
                  <HiLockClosed className="text-gray-600 dark:text-white/80" />
                </span>
              </div>
              <div className="border border-gray-200 rounded divide-y">
                <div className="px-5 py-2.5 flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="opérateur"
                    id="opérateur"
                    value="MTN"
                    onChange={(e) => setservie(e.target.value)}
                  />
                  <span className="border border-gray-200 rounded px-2 py-1 bg-gray-50">
                    MTN
                  </span>
                  <span className="dark:text-white">MTN mobile money</span>
                </div>
                <div className="px-5 py-2.5 flex items-center gap-x-2">
                  <input
                    type="radio"
                    name="opérateur"
                    id="opérateur"
                    value="ORANGE"
                    onChange={(e) => setservie(e.target.value)}
                  />
                  <span className="border border-gray-200 rounded px-2 py-1 bg-gray-50">
                    ORANGE
                  </span>
                  <span className="dark:text-white">Orange money</span>
                </div>
                <div className="p-6 dark:text-white">
                  Une fois la transaction terminé vous recevrez un sms de
                  confirmation de l'opération
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="flex flex-col basis-[35%] rounded p-8 bg-gray-50 text-sm">
          <h1 className="mt-12 text-2xl font-semibold">Résumé</h1>
          <div className="flex flex-col gap-y-4 mt-4">
            <div className="flex flex-col gap-y-2 pb-3 border-b border-gray-300">
              <div className="flex justify-between items-center">
                <h3>Prix d'origine en $</h3>
                <span>{academi.prix} $</span>
              </div>
              <div className="flex justify-between items-center">
                <h3>Prix d'origine en XAF</h3>
                <span>{converter(Number(academi.prix))} XAF</span>
              </div>
              <div className="flex justify-between items-center">
                <h3>Réduction</h3>
                <span>Aucunes</span>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <h3>Total</h3>
              <span>{converter(Number(academi.prix))} XAF</span>
            </div>
            <button
              onClick={handlePay}
              className={`${color.orange} rounded py-4 font-semibold`}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Continuer"
              )}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="md:px-[6%] mt-6">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl font-semibold mb-3 dark:text-white">
            Détails sur la commande
          </h1>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-5">
              <div>
                <img src="" alt="" />
              </div>
              <h3 className="dark:text-white/70">Description</h3>
            </div>
            <div className="dark:text-white/70">prix $</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ByeFormation;
