import React from "react";
import Statistic from "./Statistic";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
// import { snackbarContentClasses } from "@mui/material";

const Main = ({ src, title, text, but, visible, style, list }) => {
  // const finalSrc = `bg-[url('https://${src}')]`;
  const finalSrc1 = "bg-[url('" + src + "')]";

  const { t } = useTranslation();
  return (
    <div className="flex-none -z-10 w-full dark:bg-black/30">
      <div
        className={`${visible} flex justify-between flex-col lg:flex-row w-[100%] items-center dark:bg-transparent
    h-full -z-10 transition-[opacity, transform] duration-700 ease-out
    `}
        style={style}
      >
        <div className="p-[7.5%] md:pl-[10.5%] flex flex-col items-start">
          <Statistic
            main={title.toLowerCase()}
            text={text.toLowerCase()}
            dark={"dark:text-white flex-none"}
            stitle="text-[36px] text-[#fbcd13] mb-2 capitalize font-semibold md:text-[50px] leading-[60px]s"
            sp="max-w-[600px] mt-2 text-gray-300 text-xl first-letter:uppercase"
          />
          {list.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-6">
              {list?.map((el, i) => {
                if (el === "") return null;
                if (el === "undefined") {
                  return null;
                } else {
                  return (
                    <div
                      key={i}
                      className="p-4 rounded-lg bg-slate-600/20 text-white flex items-center gap-7"
                    >
                      <span className="text-[#fbcd13]">
                        <FaCheckCircle />
                      </span>
                      <span>{el}</span>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <Button
            a
            link="/connexion"
            label={t("commencer")}
            dark="dark:text-black dark:bg-[#fbcd13]"
            white="bg-[#fbcd13] md:inline px-4 py-3 font-semibold"
          />
        </div>
        <div
          className={`relative hidden md:flex top-0 bottom-0 lg:flex-none max-w-[700px] ${finalSrc1} h-full bg-no-repeat bg-cover`}
        >
          <div className="absolute top-0 bottom-0 w-full bg-gradient-to-r dark:from-[#000211] from-[#000211] to-transparent right-0 left-0"></div>
          <div className="h-full w-full flex">
            <img
              src={src}
              alt=""
              className="h-[100%] width-[100%] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
