import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";

const IsAuthenticated = ({ children }) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth);
  const path = location.pathname;

  return (
    <>
      {user.isLogin ? (
        <>{children}</>
      ) : (
        <>
          <Navigate to={`/connexion/?next=${path}`} />
        </>
      )}
    </>
  );
};

export default IsAuthenticated;
