import React from "react";
import notfound from "../assets/notfound.png"
import { color } from "../static/static";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col items-center gap-y-5">
        <img src={notfound} alt="" className="w-64 lg:w-96"/>
        <h1 className="text-sm px-10 lg:px-0 lg:text-md text-center">Délolé la page que vous rechercher n'as pas pu être trovué</h1>
        <Link to={'/'} className={`${color.orange} py-3 px-5 rounded text-sm font-medium mt-4`}>Revenir en lieu sur</Link>
      </div>
    </div>
  );
};

export default Error;
