import React from 'react';

const Box = ({children}) => {
    return (
        <div className='flex justify-between flex-wrap lg:flex-nowrap items-center gap-x-20 overflow-auto'>
            {children}
        </div>
    );
}

export default Box;
