import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const FormationApi = createApi({
  reducerPath: "FormationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_API_URL,
    prepareHeaders: (headers) => {
      const user = localStorage.getItem("user");
      if (user) {
        const userParsed = JSON.parse(user);
        headers.set("authorization", `Bearer ${userParsed.token}`);
        headers.set("Content-type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllFormations: builder.query({
      query: () => "/formation/",
    }),
    createFormation: builder.mutation({
      query: ({ data }) => {
        return {
          url: "/formation/add/",
          method: "POST",
          body: data,
        };
      },
    }),
    getOneFormation: builder.query({
      query: ({ id }) => {
        return {
          url: `/formation/${id}`
        }
      },
    }),
    getListFormation: builder.query({
      query: () => {
        return {
          url: `/formation/listFormation/`
        }
      },
    }),
    editFormation: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/formation/${id}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    deleteFormation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/formation/delete/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllFormationsQuery,
  useCreateFormationMutation,
  useDeleteFormationMutation,
  useGetOneFormationQuery,
  useEditFormationMutation,
  useGetListFormationQuery
} = FormationApi;
