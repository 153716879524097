import React from "react";
import { useTranslation } from "react-i18next";
import { useGetAllActualiteQuery } from "../api/actualiteApi";

const Editable = () => {
  const lang = localStorage.getItem("i18nextLng");
  const { data, isSuccess } = useGetAllActualiteQuery();
  const { t } = useTranslation();
  return (
    <>
      {isSuccess && (
        <div class="container flex flex-wrap items-center justify-center gap-16 lg:gap-24 max-md:flex-col max-md:items-start">
          <div class="flex flex-wrap items-center max-md:flex-col max-md:justify-center">
            <img
              alt="Join"
              draggable="false"
              loading="lazy"
              width="486"
              height="361"
              decoding="async"
              data-nimg="1"
              class=" w-full xl:w-[380px] relative object-cover rounded-lg overflow-hidden sm:px-4 sm:object-center object-center select-none duration-150 transition-[opacity]"
              //   style="color:transparent"
              src={data[0].image}
            />
          </div>
          <div class="flex max-w-[520px] flex-col items-start gap-[36px]">
            <h1 class="font-sans text-5xl max-md:text-b2 max-md:font-medium dark:text-gray-50">
              {lang === "en" ? data[0].titre.en : data[0].titre.fr}
            </h1>
            <p class="max-w-[575px] text-sub3 max-md:leading-[18px] dark:text-gray-100">
              {lang === "en" ? data[0].text.en : data[0].text.fr}
            </p>
            <div class="w-[274px] max-md:w-auto">
              <a
                aria-label="Start Now"
                class="rounded-full"
                target="_blank"
                rel="noreferrer"
                href="https://wa.link/sc6cln"
              >
                <button
                  type="button"
                  class="py-[17.5px] flex items-center gap-2 justify-center font-sans max-md:py-4 max-md:text-sub3 select-none w-full px-2 font-semibold min-w-fit transition-[background-color] duration-150 disabled:cursor-not-allowed rounded-full bg-blue bg-[#224DE6] active:bg-[#1E44CC] text-white disabled:bg-[#E9EEFF] disabled:text-[#BCCAFF]"
                >
                  {t("now")} &gt;
                </button>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Editable;
