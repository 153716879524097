import React, { useEffect, useState } from "react";
import { useGetAllFormationsQuery } from "../../api/formationApi";
import { color } from "../../static/static";
import BodyHead from "../../components/BodyHead";
import InputOwn from "../../components/InputOwn";
import { useGetAllAcademiQuery } from "../../api/AcademiApi";
import {
  useCreateformateurMutation,
  useEditformateurMutation,
  useLazyGetOneformateurQuery,
} from "../../api/formateurApi";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const ManageFormateur = () => {
  const { id } = useParams();
  const [getOneFormateur, { data: formateur, isSuccess: formasucc }] =
    useLazyGetOneformateurQuery();

  const [createformateur] = useCreateformateurMutation();
  const [editFormateur] = useEditformateurMutation();
  const { data, isSuccess } = useGetAllAcademiQuery();
  const { refetch } = useGetAllFormationsQuery();
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [academie, setAcademie] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("image", image);
    formdata.append("Nom", nom);
    formdata.append("description", description);
    formdata.append("academie", academie);
    try {
      let res;
      if (id) {
        res = await editFormateur({ data: formdata, id: id });
      } else {
        res = await createformateur({ data: formdata });
      }
      console.log(res);
      if (res.data) {
        toast.success(`${id ? "Updated " : "Created "} created`);
      } else {
        toast.error("an error occurred");
      }
      refetch();
    } catch (error) {}
  };

  const handleReset = (e) => {
    e.preventDefault();
    setNom("");
    setDescription("");
    setImage("");
    setAcademie("");
  };

  useEffect(() => {
    if (formasucc) {
      setNom(formateur.Nom);
      setDescription(formateur.description.fr);
      setAcademie(formateur.academie);
      setImage("");
    }
  }, [formasucc, formateur]);

  useEffect(() => {
    if (id) {
      getOneFormateur({ id: id });
    }
  }, [getOneFormateur, id]);
  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title={`${id ? "Modifier" : "Créer"} un formateur`} />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">
          {id ? "Modifier" : "Créer"} un formateur
        </h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Nom du formateur"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <select
                  className="block appearance-none w-full text-gray-500 text-sm bg-white border border-gray-200 hover:border-gray-500 px-4 py-4 active:right-2 pr-8 rounded-lg leading-tight focus:outline-blue-600 focus:border-blue-500"
                  value={academie}
                  onChange={(e) => {
                    setAcademie(e.target.value);
                  }}
                  placeholder="Choisisez une académie"
                >
                  <option disabled>
                    Sélectionnez le module auquel vous souhaitez relier la vidéo
                  </option>
                  {isSuccess &&
                    data.map((el, key) => (
                      <option key={key} value={el._id}>
                        {el.nom}
                      </option>
                    ))}
                </select>
                <InputOwn
                  label="Photo de profil"
                  type="file"
                  value={image}
                  Hstyle="col-span-2"
                  modifier={setImage}
                />
                <InputOwn
                  label="Numéro de téléphone"
                  type="number"
                  Hstyle={"col-span-2"}
                  children={
                    <textarea
                      placeholder="Descripion du formateur"
                      name=""
                      value={description}
                      id=""
                      cols="20"
                      rows="7"
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                    ></textarea>
                  }
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Valider
              </button>
              <button
                onClick={handleReset}
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageFormateur;
