import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../api/authApi";
import ins from "../assets/20.svg";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../slices/userSlice";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { IoIosEyeOff, IoMdEye } from "react-icons/io";
// import emailValidator from "node-email-verifier";

// async function validateEmailWithMx(email) {
//   try {
//     const isValid = await emailValidator(email);
//     console.log(
//       `Is "${email}" a valid email address with MX checking?`,
//       isValid
//     );
//   } catch (error) {
//     console.error("Error validating email with MX checking:", error);
//   }
// }

const Connexion = () => {
  const { t } = useTranslation();
  const [eye, seteye] = useState(false);
  const params = useLocation();
  const nextStep = params?.search
    ? params.search.split("=")[1].toString()
    : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp] = useLoginMutation();
  const [formData, setFormData] = useState({
    email: "",
    motdepass: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validateEmailWithMx(formData.email);
    const res = await signUp(formData);
    if (res.data) {
      localStorage.setItem("user", JSON.stringify(res.data));
      dispatch(loginSuccess(res.data));
      const url = nextStep ? nextStep : "/";
      navigate(url);
    } else {
      toast.error("invalid email or password");
    }
  };
  return (
    typeof t("conPager", { returnObjects: true }) === "object" && (
      <div className="flex justify-center h-full gap-x-20 items-center">
        <div className="flex flex-col min-w-[80%] lg:min-w-[450px] rounded-lg ">
          <h1 className="text-3xl text-center md:text-left font-medium mb-10 dark:text-white">
            {t("connexion")}
          </h1>
          <form
            action=""
            className="w-full flex flex-col gap-y-3"
            onSubmit={handleSubmit}
          >
            <div className="flex w-full">
              <input
                className="flex dark:text-white/80 w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm dark:bg-gray-500/50 focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
                required
                placeholder={t("formsMessages", { returnObjects: true }).email}
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.currentTarget.value })
                }
              />
            </div>
            <div className="flex w-full dark:text-white/80 dark:bg-gray-500/50 relative">
              <input
                className="flex w-full dark:border-none placeholder:text-gray-400/80 placeholder:text-sm bg-transparent focus:outline-none rounded ring-gray-700 dark:ring-[#FBCD13] border border-gray-400/70 focus:border-none focus:ring-1 px-4 py-4"
                required
                placeholder={t("formsMessages", { returnObjects: true }).mdp}
                type={eye ? "text" : "password"}
                value={formData.motdepass}
                onChange={(e) =>
                  setFormData({ ...formData, motdepass: e.currentTarget.value })
                }
              />
              <span
                className="text-2xl absolute right-3.5 top-[30%] z-10 cursor-pointer"
                onClick={() => seteye(!eye)}
              >
                {eye ? <IoMdEye /> : <IoIosEyeOff />}
              </span>
            </div>
            <button
              type="submit"
              className={`bg-[#FBCD13] rounded text-black text-md font-medium py-4`}
            >
              {t("conPager", { returnObjects: true }).button}
            </button>
          </form>
          <p className="mt-5 text-sm dark:text-gray-400 text-center md:text-left">
            {t("conPager", { returnObjects: true }).enMessage1}{" "}
            {t("conPager", { returnObjects: true })
              .enMessage2.split(" ")
              .map((el, key) => {
                if (key === 0) {
                  return (
                    <Link
                      to={"/inscription"}
                      key={key}
                      className={`text-[#FBCD13]`}
                    >
                      {el}
                    </Link>
                  );
                } else {
                  return ` ${el}.`;
                }
              })}
          </p>
        </div>
        <div className="basis-[36%] hidden lg:flex flex-col">
          <div>
            <img src={ins} alt="" />
          </div>
          <p className="px-8 text-sm text-center dark:text-gray-400">
            {t("conPager", { returnObjects: true }).message}
          </p>
        </div>
      </div>
    )
  );
};

export default Connexion;
