import React, { useState } from "react";
import PropTypes from "prop-types";
import BodyHead from "../components/BodyHead";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Actualite from "./Contenu/contents/Actualite";
import HomeContent from "./Contenu/contents/HomeContent";
import Publicite from "./Contenu/contents/Publicite";
import ExchangeCt from "./Contenu/contents/ExchangeCt";
import CommunityC from "./Contenu/contents/CommunityC";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Contenu = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="">
      <BodyHead title="Contenu" bouton="Ajouter" />
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Accueil" {...a11yProps(0)} />
          <Tab label="Actualité" {...a11yProps(1)} />
          <Tab label="Publicités" {...a11yProps(2)} />
          <Tab label="Exchange" {...a11yProps(3)} />
          <Tab label="Communauté" {...a11yProps(4)} />
        </Tabs>
        <div>
          <CustomTabPanel value={value} index={0}>
            <HomeContent />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Actualite />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Publicite />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ExchangeCt />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <CommunityC />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default Contenu;
