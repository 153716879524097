import React from "react";
import BodyHead from "../components/BodyHead";
import OverviewCard1 from "../components/OverviewCard1";
import { overviewCard } from "../static/static";

const DashHome = () => {
  return (
    <div className="">
      <BodyHead title="Overview" />
      <div className="grid grid-cols-3 gap-x-8 mt-10">
        {overviewCard.map((el, key) => (
          <OverviewCard1 key={key} data={el} />
        ))}
      </div>
    </div>
  );
};

export default DashHome;
