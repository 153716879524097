import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  RiWhatsappFill,
  RiTelegramFill,
  RiFacebookBoxFill,
  RiYoutubeFill,
  RiInstagramFill,
} from "react-icons/ri";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = form.current;
    if (data[0].value === "") {
      toast.error("le champs username ne peut être vide");
    } else if (data[1].value === "") {
      toast.error("le champs email ne peut être vide");
    } else if (data[2].value === "") {
      toast.error("le champs commentaire ne peut être vide");
    } else {
      emailjs
        .sendForm(
          "service_ps7s8uh",
          "template_7a59zhq",
          form.current,
          "y4h4R2xSCR0JBLgOg"
        )
        .then(
          (result) => {
            toast.success(result.text);
          },
          (error) => {
            toast.error(error.text);
          }
        );
    }
  };
  return (
    typeof t("Contact", { returnObjects: true }) === "object" && (
      <div>
        {/* <div className="px-[6.5%] w-full py-4 dark:bg-slate-900/95 bg-gray-100/70"></div> */}
        {/* -----------------contact form------------------ */}
        <div className="px-[6.5%] w-full py-14 dark:bg-slate-900/95 bg-gray-50 flex flex-col lg:flex-row gap-5">
          <div className="p-8 flex flex-col gap-y-7 bg-white shadow-lg shadow-gray-100 dark:shadow-none dark:bg-slate-900">
            <div>
              <h1 className="mb-4 text-3xl font-medium dark:text-white">
                {t("Contact", { returnObjects: true }).contInf}
              </h1>
              <p className="text-gray-500 ">
                {t("Contact", { returnObjects: true }).text}
              </p>
            </div>
            <div>
              <h3 className="font-medium dark:text-white">Email</h3>
              <p className="text-gray-500">cryptocoq@gmail.com</p>
            </div>
            <div>
              <h3 className="font-medium dark:text-white">
                {t("Contact", { returnObjects: true }).media}
              </h3>
              <div className="dark:text-white text-3xl mt-3 flex gap-2">
                <a
                  href="https://wa.link/sc6cln"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiWhatsappFill />
                </a>
                <a
                  href="https://t.me/cryptocoqacademy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiTelegramFill />
                </a>
                <a
                  href="https://web.facebook.com/elcryptocoq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiFacebookBoxFill />
                </a>
                <a
                  href="https://www.youtube.com/@elcryptocoq3196"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiYoutubeFill />
                </a>
                <a
                  href="https://instagram.com/el_cryptocoq?ig"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiInstagramFill />
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-x-5 bg-white p-8 w-full rounded shadow-lg shadow-gray-100 dark:shadow-none dark:bg-slate-900">
            <h1 className="mb-4 text-3xl font-medium dark:text-white">
              {t("Contact", { returnObjects: true }).leave}
            </h1>
            <form action="" className="" ref={form} onSubmit={handleSubmit}>
              <div className="flex flex-col gap-y-2">
                <div className="flex flex-col gap-2 lg:flex-row">
                  <div className="flex w-full border-gray-200 border dark:border-gray-600 rounded">
                    <span></span>
                    <input
                      required
                      type="text"
                      name="user_name"
                      id=""
                      className="flex w-full rounded px-5 dark:bg-gray-800 py-4 placeholder:text-gray-300 placeholder:text-sm focus:outline-none text-gray-600 dark:text-white"
                      placeholder={t("Contact", { returnObjects: true }).input1}
                    />
                  </div>
                  <div className="flex w-full border-gray-200 border dark:border-gray-600 rounded">
                    <span></span>
                    <input
                      name="user_email"
                      required
                      type="email"
                      id=""
                      className="flex w-full rounded px-5 dark:bg-gray-800 py-4 placeholder:text-gray-300 placeholder:text-sm focus:outline-none text-gray-600 dark:text-white"
                      placeholder={t("Contact", { returnObjects: true }).input2}
                    />
                  </div>
                </div>
                <textarea
                  required
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  className="border border-gray-200 dark:bg-gray-800 dark:border-gray-600 dark:text-white rounded-lg focus:outline-none text-gray-600 p-5"
                ></textarea>
                <div className="flex justify-end items-center mt-2 ">
                  <button
                    type="submit"
                    className="rounded py-3 px-4 bg-black text-[#FBCD13] dark:bg-[#FBCD13] dark:text-black text-sm"
                  >
                    {t("Contact", { returnObjects: true }).sendM}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* --------------------- helper ----------------------- */}
        {/* <div className="px-[6.5%] w-full py-14 dark:bg-slate-900/95 bg-gray-100/70">
        <Action
          st="flex flex-col gap-y-4 w-full text-center items-center"
          title="Ne perdez plus de temps"
          path="/connexion"
          text="Rejoignez nous"
        />
      </div> */}
      </div>
    )
  );
};

export default Contact;
