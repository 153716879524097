import React, { useState } from "react";
import BodyHead from "../../components/BodyHead";
import InputOwn from "../../components/InputOwn";
import { color } from "../../static/static";
import {
  useCreateFormationMutation,
  useGetAllFormationsQuery,
} from "../../api/formationApi";

const CreateFormation = () => {
  const [createFormation] = useCreateFormationMutation();
  const { refetch } = useGetAllFormationsQuery();
  const [nom, setNom] = useState("");
  const [video, setVideo] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createFormation({ data: { nom, video, description } });
      refetch();
    } catch (error) {}
  };

  const handleReset = (e) => {
    e.preventDefault();
    setNom("");
    setVideo("");
    setDescription("");
  };

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title="Créer une formation" />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">Créer une formation</h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Nom de la formation"
                  type="text"
                  value={nom}
                  modifier={setNom}
                />
                <InputOwn
                  label="Lien de la vidéo de présentation"
                  type="url"
                  value={video}
                  modifier={setVideo}
                />
                <InputOwn
                  label="Numéro de téléphone"
                  type="number"
                  Hstyle={"col-span-2"}
                  children={
                    <textarea
                      placeholder="Descripion de la formation"
                      name=""
                      value={description}
                      id=""
                      cols="30"
                      rows="10"
                      onChange={(e) => setDescription(e.target.value)}
                      className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                    ></textarea>
                  }
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                Valider
              </button>
              <button
                onClick={handleReset}
                className={`hover:bg-gray-100 text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateFormation;
