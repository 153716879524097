import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { SiGmail } from "react-icons/si";
import { RiLockPasswordLine } from "react-icons/ri";
import InputOwn from "../components/InputOwn";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateSuccess } from "../slices/userSlice";
import { useUpdateUserMutation } from "../api/userApi";
import toast from "react-hot-toast";

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  console.log(user);
  const [updateUser] = useUpdateUserMutation();
  const { t } = useTranslation();

  const [nom, setNom] = useState(user?.nom);
  const [phone, setPhone] = useState(user?.phone);
  const [image, setImage] = useState("");
  const [email, setEmail] = useState(user?.email);
  const [allowNews, setallowNews] = useState(user?.allowNews);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("image", image);
    data.append("phone", phone);
    data.append("email", email);
    data.append("allowNews", allowNews);
    data.append("nom", nom);

    const res = await updateUser({ id: user._id, data: data });
    if (res.data) {
      toast.success("mis à jour avec succès");
      const local = JSON.parse(localStorage.getItem("user"));
      localStorage.setItem("user", JSON.stringify({...local, user: res.data}));
      dispatch(updateSuccess({ user: res.data }));
    } else {
      toast.error("Une erreur est survenus");
    }
  };

  const handleReset = () => {
    setNom(user?.nom);
    setPhone(user?.phone);
    setImage(user?.image);
    setEmail(user?.image);
    setallowNews(false);
  };
  // const [state, setstate] = useState(initialState);
  return (
    <div className="px-[6.5%] w-full py-10 dark:bg-slate-900/90 bg-gray-100/70 flex items-start gap-x-5">
      <div className="bg-white dark:bg-slate-900 hidden lg:flex p-8 flex-col items-center gap-y-4 sticky top-24 rounded">
        <div className="">
          <Avatar sx={{ width: "100px", height: "100px" }} src={user.image} />
        </div>
        {user && (
          <p title={user?._id} className="font-medium dark:text-white">
            @user{user?._id?.slice(0, 10)}...
          </p>
        )}
      </div>
      <div className="bg-white w-full px-8 py-12 dark:bg-slate-900 rounded">
        <h1 className="mb-5 text-3xl font-medium dark:text-white">
          {t("connexion")}
        </h1>
        <div className="flex flex-col md:flex-row gap-3">
          <div className="w-full border border-gray-200/50 p-3 dark:border-gray-500 rounded bg-gray-50 dark:bg-white/20 dark:text-white flex justify-center items-center gap-x-4 font-medium text-gray-600">
            <span>
              <SiGmail />
            </span>
            CONNECTION PAR EMAIL
          </div>
          <div className="w-full border border-gray-200/50 dark:border-gray-500 p-3 dark:bg-white/20 dark:text-white rounded bg-gray-50 flex justify-center items-center gap-x-4 font-medium text-gray-600">
            <span>
              <RiLockPasswordLine />
            </span>
            CONNEXION AVEC MOT DE PASS
          </div>
        </div>
        <div className="mt-16 text-3xl font-medium dark:text-white">
          Données utilisateur
        </div>
        <form action="" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
            <InputOwn
              label={"username"}
              type={"text"}
              value={nom}
              modifier={setNom}
            />
            <InputOwn
              disabled
              label={"phone"}
              type={"number"}
              value={phone}
              modifier={setPhone}
            />
            <InputOwn
              disabled
              label={"email"}
              type={"email"}
              value={email}
              modifier={setEmail}
            />
            <InputOwn
              label={"photo de profil"}
              type={"file"}
              value={image}
              modifier={setImage}
            />
          </div>
          <div className="mt-16 text-3xl font-medium dark:text-white">
            Notifications
          </div>
          <div className="mt-5 border border-gray-200 dark:border-gray-500 px-8 py-5 flex justify-between items-center rounded-lg">
            <h2 className="text-sm font-medium dark:text-gray-300">
              Acceptez vous de recevoir des notification de la plateforme
              cryptocoq
            </h2>
            <Switch
              checked={allowNews}
              onChange={setallowNews}
              className={`${
                allowNews ? "bg-blue-600 dark:bg-[#FCD514]" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  allowNews ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
          <div className="mt-12 text-sm text-gray-700 dark:text-gray-400">
            reepik will process your data to send you information about our
            products and services, promotions, surveys, raffles, based on our
            legitimate interest, and updates from the creators you follow, if
            you have consented to this.
          </div>
          <div className="w-full flex justify-end gap-x-5 mt-20">
            <button
              className="border border-gray-200 dark:border-gray-500 dark:text-white px-6 rounded py-2.5"
              onClick={handleReset}
            >
              Cancel
            </button>
            <button
              className="px-6 rounded py-2.5 bg-blue-600 dark:bg-[#FCD514] dark:text-black text-white"
              type="submit"
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
