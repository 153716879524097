import React, { useEffect, useState } from "react";
import BodyHead from "../../components/BodyHead";
import { useNavigate, useParams } from "react-router-dom";
import InputOwn from "../../components/InputOwn";
import { color } from "../../static/static";
import {
  useCreateVideoMutation,
  useEditVideoMutation,
  useGetAllVideoQuery,
  useLazyGetOneVideoQuery,
} from "../../api/VideoApi";
import { useGetAllModulesQuery } from "../../api/ModuleApi";
import toast from "react-hot-toast";

const ManageVid = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, isSuccess } = useGetAllModulesQuery();
  const [
    getOneVideo,
    { data: videodata, isSuccess: succ },
  ] = useLazyGetOneVideoQuery();
  const { refetch } = useGetAllVideoQuery();

  const [title, setTitle] = useState("");
  const [link, setVideo] = useState("");
  const [duree, setDuree] = useState("");
  const [module, setModule] = useState("");

  const [createVideo] = useCreateVideoMutation();
  const [editVideo] = useEditVideoMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = null;
    if (id) {
      res = await editVideo({ id: id, data: { title, link, duree, module } });
    } else {
      res = await createVideo({
        data: { title, link, duree, module },
      });
    }
    if (res.data) {
      if (id) {
        toast.success("Modifier avec succès");
      } else {
        toast.success("Créer avec succès");
      }
      refetch();
    }else{
      toast.error("Une erreur est survenue")
    }
  };

  useEffect(() => {
    if (id) {
      setTitle(videodata ? videodata?.title : "");
      setVideo(videodata ? videodata?.link : "");
      setDuree(videodata ? videodata?.duree : "");
      setModule(videodata?.module);
    }
  }, [succ, videodata, id]);

  useEffect(() => {
    if (id) {
      getOneVideo({ id: id });
    }
  }, [navigate, getOneVideo, id]);

  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title={id ? "Editer une vidéo" : "Ajouter une vidéo"} />
      <div className="my-4 mb-16 p-6 rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">
          {id ? "Editer une vidéo" : "Ajouter une vidéo"}
        </h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Titre de la vidéo"
                  type="text"
                  value={title}
                  modifier={setTitle}
                />
                <div className="relative inline-block">
                  <select
                    className="block appearance-none w-full text-gray-500 text-sm bg-white border border-gray-200 hover:border-gray-500 px-4 py-4 active:right-2 pr-8 rounded-lg leading-tight focus:outline-blue-600 focus:border-blue-500"
                    value={module}
                    onChange={(e) => {
                      setModule(e.target.value);
                    }}
                    placeholder="Choisisez une académie"
                  >
                    <option disabled>
                      Sélectionnez le module auquel vous souhaitez relier la
                      vidéo
                    </option>
                    {!isLoading &&
                      isSuccess &&
                      data.map((el, key) => (
                        <option key={key} value={el._id}>
                          {el.nom}
                        </option>
                      ))}
                  </select>
                  <div className="absolute top-1/2 end-2.5 -translate-y-1/2">
                    <svg
                      className="flex-shrink-0 w-4 h-4 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <InputOwn
                  label="Entrez le lien de la vidéo"
                  type="url"
                  Hstyle={"col-span-2"}
                  value={link}
                  modifier={setVideo}
                />
                <InputOwn
                  label="Entrez la durée de la vidéo"
                  type="number"
                  Hstyle={"col-span-2"}
                  value={duree}
                  modifier={setDuree}
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                {id ? "Editer" : "Valider"}
              </button>
              <span
                className={`hover:bg-gray-100 cursor-pointer text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageVid;
