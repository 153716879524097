import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import logod from "../../assets/logoy.png";
import logow from "../../assets/logo.png";
import { useState } from "react";
import "../Header/style.css";
import { HiLogout, HiOutlineMenu } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import Button from "../Button";
import { useTheme } from "../../hooks/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../slices/userSlice";
import { Avatar } from "@mui/material";
import { useLogoutMutation } from "../../api/authApi";
import { deconnexion } from "../../slices/userSlice";
import { useTranslation } from "react-i18next";
import LangDropDown from "../LangDropDown";
import ShadeMode from "../ShadeMode";

const Header = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const test = t("HeaderLinks", { returnObjects: true });

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const [show, setShow] = useState(true);
  const { theme, darkQuery } = useTheme();

  const closeMenu = (e) => {
    setShow(true);
  };
  const openMenu = (e) => {
    setShow(false);
  };

  const handleLogout = async (e) => {
    try {
      dispatch(deconnexion());
      await logout();
      localStorage.removeItem("user");
      navigate("/")
    } catch (error) {}
  };

  return (
    <div className="sticky top-0 z-40 bg-white shadow-lg">
      <nav className="flex flex-wrap px-[6%] justify-between items-center py-4  shadow-slate-100 dark:shadow-none dark:bg-gray-900 w-full ">
        <div>
          <Link
            to="/"
            className="flex flex-nowrap items-center gap-1 cursor-pointer"
          >
            <img
              src={
                theme === "dark"
                  ? logod
                  : theme === "light"
                  ? logow
                  : theme === "system" && darkQuery.matches
                  ? logod
                  : logow
              }
              alt="logo"
              className="w-10"
            />
            <h1 className="text-lg font-semibold dark:text-white">CRYPTOCOQ</h1>
          </Link>
        </div>
        {
          <ul
            className={`
          dark:text-white dark:bg-slate-900 bg-white text-black pt-16 z-50 lg:z-50 flex text-[14px] flex-col fixed top-0 reveal ${
            show
              ? "right-[-100%] md:right-[-380px] z-50 opacity-0 lg:opacity-100"
              : "right-0 opacity-1"
          } h-full w-[100%] md:w-[380px] lg:text-md lg:pt-0 lg:space-x-6 lg:flex-row lg:w-auto bg-gray-800 lg:static lg:bg-transparent`}
          >
            {typeof test === "object" &&
              test.map((el, key) => (
                <NavLink
                  key={key}
                  to={el.link}
                  onClick={(e) => closeMenu()}
                  className="mx-[25px] p-3.5 text-md font-medium uppercase lg:p-0 lg:block lg:m-0 hover:bg-gray-50 lg:dark:hover:bg-transparent dark:hover:bg-gray-700 lg:hover:bg-transparent"
                >
                  {el.lable}
                </NavLink>
              ))}
            {!user && (
              <li className="flex gap-3 mx-[25px] mt-5 lg:hidden">
                <Button
                  label={t("inscription")}
                  link={"/inscription"}
                  dark={"dark:text-white dark:bg-gray-700"}
                  white={"bg-gray-100"}
                />
                <Button
                  label={t("connexion")}
                  link={"/connexion"}
                  dark={"dark:text-black dark:bg-[#fbcd13]"}
                  white={"bg-black text-[#fbcd13]"}
                />
              </li>
            )}
            <li
              className="absolute top-5 left-8 lg:hidden text-2xl"
              onClick={closeMenu}
            >
              <RxCross1 />
            </li>
          </ul>
        }
        <div className="flex items-center gap-y-2 gap-x-3">
          {!user && (
            <div>
              <Button
                label={t("inscription")}
                link={"/inscription"}
                dark={"dark:text-white"}
                white={"bg-transparent hidden lg:inline"}
              />
              <Button
                label={t("connexion")}
                link={"/connexion"}
                dark={"dark:text-black dark:bg-[#fbcd13]"}
                white={"bg-black text-[#fbcd13] hidden md:inline"}
              />
            </div>
          )}
          <LangDropDown />
          <ShadeMode />
          {user && (
            <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
              <div
                id="hs-dropdown-basic"
                className="hs-dropdown-toggle disabled:opacity-50 disabled:pointer-events-none w-10 flex duration-300 items-center rounded-full object-cover"
                // onClick={() => setshowInfos(!showInfos)}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={user.image ? user.image : null}
                  sx={{ height: "30px", width: "30px" }}
                />
              </div>
              <div
                className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0  hidden z-10 mt-2 min-w-[15rem] bg-white shadow-lg shadow-gray-200/60 dark:shadow-none rounded-lg p-5 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                aria-labelledby="hs-dropdown-basic"
                // className={`before:content-[''] before:ml-0.5 before:text-red-500 before:block before:absolute before:-top-1 before:bg-white before:p-2 before:rotate-45 before:right-4`}
              >
                <div className={`flex flex-col gap-y-3`}>
                  <div className="flex flex-col gap-y-4 border-b border-b-gray-200 dark:border-b-gray-600 pb-5 items-start">
                    <Avatar
                      alt="Remy Sharp"
                      src={user.image}
                      sx={{ height: "70px", width: "70px" }}
                    />
                    <div className="flex flex-col items-start">
                      <span className="text-sm font-medium text-gray-500">
                        {user && user.username}
                      </span>
                      <span className="text-sm font-normal text-gray-400">
                        {user && user.email}
                      </span>
                      <Link
                        to={`/profile`}
                        className="text-black bg-amber-400 py-2 px-6 rounded-sm mt-4 text-sm"
                      >
                        {t("profile", { returnObjects: true }).edit}
                      </Link>
                    </div>
                  </div>
                  <div className="">
                    <ul className="flex flex-col gap-y-3 text-gray-600">
                      <li>
                        <Link
                          className="uppercase dark:text-gray-400"
                          href="#f"
                        >
                          <i className="bi bi-archive mr-2 uppercase"></i>{" "}
                          {t("profile", { returnObjects: true }).subs}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="uppercase dark:text-gray-400"
                          href="#f"
                        >
                          <i className="bi bi-journal-album mr-2 uppercase "></i>{" "}
                          {t("profile", { returnObjects: true }).cour}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="uppercase dark:text-gray-400"
                          to={"community"}
                        >
                          <i className="bi bi-people mr-2 uppercase"></i>{" "}
                          {t("profile", { returnObjects: true }).comunaute}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="uppercase dark:text-gray-400"
                          to="/exchange#faq"
                        >
                          <i className="bi bi-question-circle mr-2"></i>{" "}
                          {t("profile", { returnObjects: true }).Faq}
                        </Link>
                      </li>
                      <li className="h-[1px] bg-gray-200 dark:bg-gray-600"></li>
                      <li onClick={handleLogout} className="cursor-pointer">
                        <span className="flex justify-between items-center px-2 uppercase dark:text-gray-400">
                          <span>
                            {t("profile", { returnObjects: true }).decon}
                          </span>
                          <HiLogout />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            className="block text-black dark:text-white text-2xl lg:hidden"
            onClick={openMenu}
          >
            <HiOutlineMenu />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Header;
