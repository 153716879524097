import lock from "../assets/8.svg";
import wifi from "../assets/9.svg";
import wallet from "../assets/10.svg";
// import im1 from "../assets/im1.jpg";
// import im2 from "../assets/im2.jpg";
// import im3 from "../assets/im3.jpg";
// import bit from "../assets/bit2.png";
import im4 from "../assets/4.svg";
import im5 from "../assets/5.svg";
import im6 from "../assets/6.svg";
import im7 from "../assets/16.svg";
import im8 from "../assets/17.svg";
import im9 from "../assets/18.svg";
import el4 from "../assets/im4.jpg";
import el5 from "../assets/im5.jpg";
import el6 from "../assets/im6.jpg";
import icon1 from "../assets/iconly-glass-info.svg"
import icon2 from "../assets/iconly-glass-paper.svg"
import icon3 from "../assets/iconly-glass-tick.svg"
import { RiHomeSmile2Line, RiBook2Line, RiMoneyCnyBoxLine, RiUser2Line, RiSoundModuleLine, RiSchoolLine, RiVideoAddLine, RiSettings2Line, RiAddCircleLine, RiUser5Line } from "react-icons/ri";

export const sideLinks = [
  {
    links: [
      {
        url: 'home',
        liens: "Home",
        icon: RiHomeSmile2Line,

      },
    ],
  },
  {
    title: "Gestion",
    links: [
      {
        url: 'contenu',
        liens: "Contenus",
        icon: RiAddCircleLine,
      },
      {
        url: 'formation',
        liens: "Formations",
        icon: RiBook2Line,
      },
      {
        url: 'utilisateur',
        liens: "Utilisateurs",
        icon: RiUser2Line,
      },
      {
        url: 'formateur',
        liens: "Formateurs",
        icon: RiUser5Line,
      },
      {
        url: 'paiement',
        liens: "Paiement",
        icon: RiMoneyCnyBoxLine,
      },
      {
        url: 'academie',
        liens: "Académie",
        icon: RiSchoolLine,
      },
      {
        url: 'module',
        liens: "Module",
        icon: RiSoundModuleLine,
      },
      {
        url: 'video',
        liens: "Vidéos",
        icon: RiVideoAddLine,
      },
    ]
  },
  {
    title: "Paramètres",
    links: [
      {
        url: 'home',
        liens: "Administrateurs",
        icon: RiSettings2Line,

      },
    ],
  },
];

export const color = {
  orange: "bg-[#FBCD13]",
  textorange: "text-[#FBCD13]",
  navbg: "bg-[#1C2536]",
  secondary: "bg-[#6366F1]",
  primary: "text-[#6366F1]",
  text: "text-[#1C2536]",
};

export const counter = [
  {
    id: "1",
    main: "$38 billion",
    text: "lorem ipsum dolor adipisicing",
  },
  {
    id: "2",
    main: "350+",
    text: "lorem ipsum dolor adipisicing",
  },
  {
    id: "3",
    main: "120 million",
    text: "lorem ipsum dolor adipisicing",
  },
  {
    id: "4",
    main: "0.10%",
    text: "lorem ipsum dolor adipisicing",
  },
];

export const infoblock1 = [
  {
    id: 1,
    img: lock,
    title: "Lorem, ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa numquam explicabo quod",
  },
  {
    id: 2,
    img: wifi,
    title: "Lorem, ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa numquam explicabo quod",
  },
  {
    id: 3,
    img: wallet,
    title: "Lorem, ipsum dolor",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa numquam explicabo quod",
  },
];

export const news = [
  {
    id: 1,
    img:
      "https://plus.unsplash.com/premium_photo-1661762849724-df5bbebb8755?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Lorem ipsum dolor",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa officia minima perferendis error?",
  },
  {
    id: 2,
    img:
      "https://images.unsplash.com/photo-1526948531399-320e7e40f0ca?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Lorem sit amet",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa officia minima perferendis error",
  },
  {
    id: 3,
    img:
      "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Lorem dolor",
    text:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa officia minima perferendis error",
  },
];

export const footerData = [
  {
    title: "Liens utiles",
    links: [
      {
        name: "Home",
        path: "/",
      },
      {
        name: "Exchange",
        path: "/exchange",
      },
      {
        name: "Community",
        path: "/community",
      },
      {
        name: "News",
        path: "/news",
      },
      {
        name: "Contacts",
        path: "/contact",
      },
    ],
  },
  {
    title: "Informations",
    links: [
      {
        name: "FAQ",
        path: "/exchange#faq",
      },
      {
        name: "Qui Sommes Nous",
        path: "/element",
      },
      {
        name: "Tearms of Uses",
        path: "/element",
      },
      {
        name: "Privacy Policy",
        path: "/element",
      },
      
    ],
  },
  {
    title: "Contact",
    links: [
      {
        name: "About Us",
        path: "/element",
      },
      {
        name: "Tearms of use",
        path: "/element",
      },
      {
        name: "Pricacy policy",
        path: "/element",
      },
      {
        name: "Cookie policy",
        path: "/element",
      },
      {
        name: "Community rules",
        path: "/element",
      },
    ],
  },
  {
    title: "Reseaux",
    links: [
      {
        name: "About Us",
        path: "/element",
      },
      {
        name: "Tearms of use",
        path: "/element",
      },
    ],
  },
];

export const formations = [
  {
    id: 1,
    title: "Introduction et fondamentaux",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae officiis autem excepturi dolorum itaque et, enim",
    video: "link",
  },
  {
    id: 2,
    title: "Approfondissement et strategies",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae officiis autem excepturi dolorum itaque et, enim",
    video: "link",
  },
  {
    id: 3,
    title: "Exercice et spécialisation",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae officiis autem excepturi dolorum itaque et, enim",
    video: "link",
  },
  {
    id: 4,
    title: "Mise en situation au cameroun",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae officiis autem excepturi dolorum itaque et, enim",
    video: "link",
  },
];

export const carousel = [
  {
    id: 1,
    title: "Slide one information",
    text:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tenetur eos id corporis numquam doloribus sapiente architecto, cum, nesciunt fugit at? Molestias a earum odio!",
    src: im4,
  },
  {
    id: 2,
    title: "Slide two infos",
    text:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tenetur eos id corporis numquam doloribus sapiente architecto, cum, nesciunt fugit at? Molestias a earum odio!",
    src: im5,
  },
  {
    id: 3,
    title: "Last slide page",
    text:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tenetur eos id corporis numquam doloribus sapiente architecto, cum, nesciunt fugit at? Molestias a earum odio!",
    src: im6,
  },
];

export const mainExchange = {
  minTitle: "Pay and get paid with crypto",
  title: (
    <p>
      Welcome to CryptoCOQ
      <br />
      <span className="text-[#FBCD13]">#</span>
      <span className="text-[#FBCD13]">exchange </span>lifestyle
    </p>
  ),
  text:
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tenetur eos id corporis numquam doloribus sapiente architecto, cum, nesciunt fugit at? Molestias a earum odio",
  links: [
    {
      name: "Rejoindre la comunauté",
      path: "http://rejoindre",
    },
    {
      name: "commencer",
      path: "connexion",
    },
  ],
};

export const mainCommunity = {
  minTitle: "",
  title: (
    <>
      Welcome to the CryptoCOQ <br className="hidden md:block"/>
      <span className="text-[#FBCD13] md:ml-2">Community</span>
    </>
  ),
  text:
    "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Rerum tenetur eos id corporis numquam doloribus sapiente architecto, cum, nesciunt fugit at? Molestias a earum odio",
  links: [
    {
      name: "Rejoindre la comunauté",
      path: "http://rejoindre",
    },
    {
      name: "commencer",
      path: "connexion",
    },
  ],
};

export const faqData = [
  {
    id: 1,
    title: "Who can use Cryptocoq exchange",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
  {
    id: 2,
    title: "What about cryptocurences",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
];

export const listRaisons = [
  "Un service rapide et fiable pour votre satisfaction",
  "Des compétences approfondie dans le domaine de la crypto",
  "Un suivi de qualité des apprenant inscrit",
  "Une formation à la pointe de l'actualité",
  "Des stratégies pour faire grandir votre portefeuil",
  "Des formateurs qualifié et exprérimenté",
];

export const ecosystemData = [
  {
    link: "/connexion",
    img: im7,
    title: "Cryptocoq Exchange",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium totam! Laudantium, voluptate?",
  },
  {
    link: "/connexion",
    img: im8,
    title: "Cryptocoq Labs",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium totam! Laudantium, voluptate?",
  },
  {
    link: "/connexion",
    img: im9,
    title: "Cryptocoq Research",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium totam! Laudantium, voluptate?",
  },
];

export const comCardData = [
  {
    title: "Explore Events",
    text: [
      "Morem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
      "Repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
    ],
    img: el4,
  },
  {
    title: "Join The Community",
    text: [
      "Morem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
      "Repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
    ],
    img: el5,
  },
  {
    title: "Improve your knowledge",
    text: [
      "Morem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti impedit voluptatem ullam blanditiis vitae ab, ut accusantium",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
      "Repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
    ],
    img: el6,
  },
];

export const mission = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
  {
    id: 3,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
  {
    id: 4,
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
    text:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim quia hic autem placeat cum repellendus voluptas rem voluptate debitis. Itaque cupiditate quaerat eaque reprehenderit minima consequuntur corporis amet maxime rerum.",
  },
];

export const formation = [
  {
    title: "Bien démarer la formation ",
    sessions: [
      {
        label: "Comment bien débuter la programmation",
        duree: "03:20",
      },
      {
        label: "Quick start apprenez tout de suite",
        duree: "02:40",
      },
      {
        label: "Félicitation continuez sur votre lancé",
        duree: "04:10",
      },
    ],
  },
  {
    title: "Les bases de python [Débutant]",
    sessions: [
      {
        label: "Comment bien débuter la programmation",
        duree: "03:20",
      },
      {
        label: "Quick start apprenez tout de suite",
        duree: "02:40",
      },
      {
        label: "Félicitation continuez sur votre lancé",
        duree: "04:10",
      },
    ],
  },
];

export const overviewCard = [
  {
    icon: icon3,
    label: "Done tasks",
    value: "31",
    next: "See all tasks"
  },
  {
    icon: icon2,
    label: "Pending Issues",
    value: "12",
    next: "See all issues"
  },
  {
    icon: icon1,
    label: "Open tickets",
    value: "5",
    next: "See all tickets"
  },
]

export const faqsList = [
  {
      q: "What are some random questions to ask?",
      a: "That's exactly the reason we created this random question generator. There are hundreds of random questions to choose from so you're able to find the perfect random question."
  },
  {
      q: "Do you include common questions?",
      a: "This generator doesn't include most common questions. The thought is that you can come up with common questions on your own so most of the questions in this generator."
  },
  {
      q: "Can I use this for 21 questions?",
      a: "Yes! there are two ways that you can use this question generator depending on what you're after. You can indicate that you want 21 questions generated."
  },
  {
      q: "Are these questions for girls or for boys?",
      a: "The questions in this generator are gender neutral and can be used to ask either male of females (or any other gender the person identifies with)."
  },
  {
      q: "What do you wish you had more talent doing?",
      a: "If you've been searching for a way to get random questions, you've landed on the correct webpage. We created the Random Question Generator to ask you as many random questions as your heart desires."
  }
]