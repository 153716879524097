import React, { useEffect, useState } from "react";
import BodyHead from "../../components/BodyHead";
import { useNavigate, useParams } from "react-router-dom";
import InputOwn from "../../components/InputOwn";
import { color } from "../../static/static";
import {
  useCreateContentMutation,
  useLazyGetOneContenuQuery,
  useUpdateContentMutation,
} from "../../api/contenuApi";
import toast from "react-hot-toast";

const ManageContenu = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createContent] = useCreateContentMutation();
  const [updateContent] = useUpdateContentMutation();
  const [getOneContenu, { data: cont, isSucces }] = useLazyGetOneContenuQuery();

  const [titre, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [text, setText] = useState("");
  const [list, setList] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("image", image);
    data.append("titre", titre);
    data.append("text", text);
    data.append("list", list);

    if (id) {
      getOneContenu(id);
    }
    let res = null;
    if (id) {
      res = await updateContent({ data: data, id: id });
    } else {
      res = await createContent({ data: data });
    }
    if (res.data) {
      toast.success(
        !id ? "contenu ajouté avec succès" : "contenus mis à jour avec succès"
      );
      if (!id) {
        setImage("");
        setText("");
        setTitle("");
      } else {
        // refetch()
      }
    } else if (res.error) {
      toast.error("Une erreur est survenue");
    }
  };

  useEffect(() => {
    setImage("");
    setText(cont?.contenu?.text);
    setTitle(cont?.contenu?.titre);
    setList(cont?.contenu?.list.join("-"));
  }, [isSucces, cont]);

  useEffect(() => {
    if (id) {
      getOneContenu(id);
    }
  }, [navigate, getOneContenu, id]);
  return (
    <div className="flex flex-col gap-y-10">
      <BodyHead title={id ? "Editer un contenu" : "Ajouter un contenu"} />
      <div className="my-4 mb-16 p-6 h-full rounded-xl shadow-lg shadow-gray-400/10 border border-gray-100 flex flex-col">
        <h3 className="font-medium text-lg">
          {id ? "Editer un contenu" : "Ajouter un contenu"}
        </h3>
        <div className="mt-5">
          <form action="">
            {
              <div className="grid grid-cols-2 gap-5">
                <InputOwn
                  label="Titre du contenu"
                  type="text"
                  value={titre}
                  modifier={setTitle}
                />
                <div className="flex gap-3">
                  <InputOwn
                    Hstyle={"w-full"}
                    label="Entrez l'image"
                    type="file"
                    value={image}
                    modifier={setImage}
                  />
                  {cont?.image !== "" && (
                    <div className="w-14 h-14 ml-auto hover:bg-gray-50 rounded-full hover:shadow-lg hover:shadow-gray-100 flex justify-center items-center">
                      <img
                        src={cont?.contenu?.image}
                        alt=""
                        className="object-contain"
                      />
                    </div>
                  )}
                </div>
                <InputOwn
                  label="Entrez le texte"
                  type="text"
                  Hstyle={"col-span-2"}
                  value={text}
                  modifier={setText}
                />
                <InputOwn
                  Hstyle={"col-span-2"}
                  children={
                    <textarea
                      value={list}
                      onChange={(e) => setList(e.target.value)}
                      placeholder="Ajouter une liste d'éléments séparés par des ' - '"
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="w-full placeholder:text-sm p-5 border border-gray-200 focus:outline-[#6366F1] rounded-lg"
                    ></textarea>
                  }
                />
              </div>
            }
            <div className="flex gap-x-4 mt-10">
              <button
                onClick={handleSubmit}
                className={`${color.secondary} text-white text-sm rounded-lg px-5 font-medium py-3`}
              >
                {id ? "Editer" : "Valider"}
              </button>
              <span
                className={`hover:bg-gray-100 cursor-pointer text-slate-600 duration-150 text-sm rounded-lg px-5 font-medium py-3`}
              >
                Annuler
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageContenu;
