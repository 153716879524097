import { Skeleton } from "@mui/material";
import React from "react";

const Skel = () => {
  return (
    <div className="flex bg-white rounded h-[240px] w-full">
      <div className="w-[300px] flex h-full overflow-hidden flex-none">
        <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
      </div>
      <div className="p-7 flex flex-col gap-y-2 w-full">
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} height={100} />
        <div className="flex justify-between items-center mt-auto">
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
        </div>
      </div>
    </div>
  );
};

export default Skel;
