import React, { useLayoutEffect, useState } from "react";
import AnimatedAcordeon from "../components/AnimatedAcordeon";
import { Link, useParams } from "react-router-dom";
import { useGetOneAcademiQuery } from "../api/AcademiApi";
import Statistic from "../components/Statistic";
import { useTranslation } from "react-i18next";
import { useGetListformateurQuery } from "../api/formateurApi";
import { CircularProgress } from "@mui/material";
import Modals from "../components/Modals";
import { useSelector } from "react-redux";

const reducerV = (accumulator, duree) => {
  const accD = !Number.isNaN(accumulator)
    ? Number(accumulator.duree)
    : Number(accumulator);
  const nextD = duree?.duree;
  return Number(accD) + Number(nextD);
};

const sessionReducer = (accumulator, value) => {
  const videLenght = Array.isArray(accumulator?.video)
    ? accumulator?.video?.length
    : accumulator;
  const valTotal = value?.video?.length;
  return videLenght + valTotal;
};

const reducer = (accumulator, value) => {
  const videoTotal = accumulator?.video
    ? accumulator.video.reduce(reducerV)
    : 0 + accumulator;
  const valTotal = value.video.reduce(reducerV);
  // console.log(videoTotal, valTotal)
  return videoTotal + valTotal;
};

const FormationInfo = () => {
  const { user } = useSelector((state) => state.auth);
  const [activLink, setactivLink] = useState("");
  const [isOpen, setisOpen] = useState(false);
  const [activeTitle, setactiveTitle] = useState("");
  const lang = localStorage.getItem("i18nextLng");
  const { id } = useParams();
  const {
    data: academi,
    isSuccess,
    isLoading,
  } = useGetOneAcademiQuery({ id: id });
  const {
    data: formateurs,
    isSuccess: formaSuccess,
    isLoading: load,
  } = useGetListformateurQuery({
    id: id,
  });

  let sessions = isSuccess && academi.modules.reduce(sessionReducer);
  let totalTime = isSuccess && academi.modules.reduce(reducer);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (isSuccess) {
      setactivLink(academi.modules[0].video[0].link);
      setactiveTitle(academi.modules[0].video[0].title);
    }
  }, [isSuccess, setactivLink, academi]);

  if (isLoading || load) {
    return (
      <div className="dark:bg-slate-900 relative h-full flex justify-center items-center">
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div className="dark:bg-slate-900">
      <Modals
        academi={academi}
        activLink={activLink}
        activeTitle={activeTitle}
        setactivLink={setactivLink}
        setactiveTitle={setactiveTitle}
        close={() => setisOpen(false)}
        open={isOpen}
      />
      {/* <div className="absolute w-full h-screen top-0 bottom-0 bg-black/60 overflow-y-scroll py-40 flex justify-center items-start z-[100]">
        <div className="w-[680px] min-h-[800px] pb-6 bg-gray-100 rounded">
          <div className="p-6">
            <div className="flex justify-between w-full items-center">
              <h3>Aperçu du cours</h3>
              <div className="w-8 h-8 rounded-full bg-gray-200 flex justify-center items-center">
                <MdClose />
              </div>
            </div>
            <div className="mt-3 flex items-center gap-2">
              <h1 className="font-medium text-lg">{academi.nom} :</h1>
              <span className="first-letter:capitalize text-lg font-medium">
                {academi.title.toLowerCase()}
              </span>
            </div>
            <div className="mt-5">
              <ReactPlayer controls={true} width={"100%"} url={activLink || ""} />
            </div>
          </div>
          <div className="">
            <h1 className="text-lg px-6 font-medium">Liste des vidéos:</h1>
            <div>
              {academi.modules.map((el) => (
                <div>
                  <div className="border-b border-b-gray-200 text-gray-500 mx-6 py-1">
                    {el.nom}
                  </div>
                  <div className="flex flex-col gap-1">
                    {el.video.map((vid) => (
                      <div
                        onClick={() => {
                          setactivLink(vid.link);
                          setactiveTitle(vid.title);
                        }}
                        className={`${
                          activeTitle === vid.title ? "bg-gray-200/80" : ""
                        } px-6 py-4 flex gap-3 cursor-pointer items-center w-full`}
                      >
                        <div className="w-20 h-[50px] rounded bg-red-600 overflow-hidden">
                          <ReactPlayer
                            controls={true}
                            width={"100%"}
                            url={vid.link}
                          />
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <h1 className="text-sm font-medium flex items-center gap-2">
                            { (activeTitle === vid.title) && <IoMdPlay className="text-lg"/>} {vid.title.slice(0, 40)}...
                          </h1>
                          <span className="text-sm font-medium">
                            {("0" + vid.duree).slice(-2)}:00
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      <div className="px-[6.5%] w-full h-[800px] mb-80 py-6 dark:bg-slate-900/95 bg-gradient-to-r from-gray-800 to-gray-900">
        <div className="flex flex-col justify-between items-center gap-x-24 gap-y-10 py-12">
          <div className="flex flex-col items-center space-y-5 w-full text-lg ">
            {
              <div className="flex flex-col text-center space-y-6">
                <Statistic
                  main={
                    lang === "en"
                      ? academi.en.title.toLowerCase()
                      : academi.title.toLowerCase()
                  }
                  text={""}
                  stitle={
                    "dark:text-gray-100 font-poppins text-gray-50 text-[42px] md:text-[54px] font-medium leading-tight capitalize"
                  }
                />
                <p className="text-gray-200 dark:text-gray-400 text-sm max-w-4xl xl:text-lg break-all word-break first-letter:uppercase">
                  {lang === "en"
                    ? academi?.en?.description?.toLowerCase()
                    : academi?.description?.toLowerCase()}
                </p>
              </div>
            }
            {!academi.payement.includes(user._id) && (
              <div className="mt-4 card1">
                <Link to={`/paiement/${id}`}>
                  <button className="capitalize mt-6 px-6 py-3 bg-gradient-to-r rounded-lg text-lg text-gray-200 from-blue-500 to-blue-600">
                    {t("buynow")}
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full relative z-10 top-16 gap-10 justify-center items-center">
            <h1 className="dark:text-gray-100 font-poppins text-gray-50 text-[42px] md:text-[48px] text-center font-medium leading-tight capitalize">
              {t("intro")}
            </h1>
            <div className="card1 border-4 border-white/10 shadow-xl  backdrop-blur-sm flex justify-center max-[1150px]:justify-start w-9/12 max-[1150px]:w-full max-[450px]:w-full ">
              {
                <iframe
                  className="w-full rounded h-[420px] lg:h-[520px]"
                  title="start video"
                  frameBorder="0"
                  src={academi?.video.toString()}
                  allowFullScreen
                ></iframe>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="px-[6.5%] w-full mb-6 dark:bg-slate-900/95 bg-white">
        <h1 className="mb-16 dark:text-gray-100 font-poppins text-gray-800 text-center font-semibold text-[42px] md:text-[52px] leading-tight capitalize">
          {t("formateurs")}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center gap-10">
          {formaSuccess &&
            formateurs.map((v, i) => (
              <div key={i}>
                <div className="h-80 bg-[#FBCD13] overflow-hidden">
                  {v.image && (
                    <img src={v.image} alt="" className="object-cover" />
                  )}
                </div>
                <div className="p-4 flex flex-col gap-5">
                  <h2 className="font-semibold text-2xl dark:text-gray-100">
                    {v.Nom}
                  </h2>
                  <p className="dark:text-gray-200">
                    {lang === "en" ? v.description.en : v.description.fr}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <div className="px-[6.5%] w-full mt-24 grille py-14 dark:bg-slate-900 bg-gradient-to-r from-gray-800 to-gray-900">
        <div className="card1 flex flex-col space-y-6 p-10 max-[350px]:p-5">
          <div className="flex flex-col space-y-6">
            <h1 className="text-xl font-medium text-center text-gray-100 dark:text-white xl:text-[48px] mb-16">
              {t("whatlearn")}
            </h1>
          </div>
          <div>
            <ul className="grid grid-cols-2 gap-8 max-[1150px]:grid-cols-1">
              {academi.modules.slice(0, 6).map((el, key) => (
                <li className="flex items-center gap-4" key={key}>
                  <span className="text-green-500 text-xl dark:text-[#FCD515]">
                    <FaCheckCircle />
                  </span>
                  <span className="text-gray-100 dark:text-gray-100">
                    {el.nom}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div> */}
      <div className="px-[6.5%] w-full py-20 grille dark:bg-slate-900/95 bg-white">
        {
          <div className="flex flex-col items-center max-[650px]:px-2 pt-10 pb-10">
            <h1 className="text-xl text-center font-semibold text-gray-800 xl:text-[46px] dark:text-white mb-10">
              {t("contenuCour")}
            </h1>
            {!academi.payement.includes(user._id) && (
              <div className="inline-flex mt-3 mb-5 sm:w-auto">
                <Link
                  to={`/paiement/${id}`}
                  className="inline-flex items-center justify-center w-full px-10 py-3 text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                >
                  {t("buynow")}
                </Link>
              </div>
            )}
            <div className="text-gray-600 mb-3 text-sm dark:text-gray-400 self-start">
              {academi.modules.length} sections .{sessions} sessions . Durée:{" "}
              {120} min
            </div>
            <div className="border border-gray-100 dark:border-gray-600 rounded w-full">
              {academi.modules.map((el, key) => (
                <AnimatedAcordeon
                  buy={academi.payement.includes(user._id)}
                  data={el}
                  key={key}
                  open={() => setisOpen(true)}
                />
              ))}
            </div>
          </div>
        }
      </div>
      <div className="w-full py-8 pt-16 dark:bg-gray-900">
        <section className="bg-gray-800 dark:bg-gray-900 py-4">
          <div className="container flex flex-col items-center px-4 py-12 mx-auto text-center">
            <h2 className="max-w-2xl mx-auto text-2xl font-medium tracking-tight text-gray-100 xl:text-4xl mb-2 dark:text-white">
              {t("nextlevel")}
            </h2>
            {!academi.payement.includes(user._id) && (
              <div className="inline-flex w-full mt-6 sm:w-auto">
                {/* <span
                onClick={paymen}
                className="inline-flex items-center justify-center w-full px-10 py-3 text-white duration-300 bg-blue-600 rounded-full hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              >
                {t("buynow")}
              </span> */}
                <Link
                  to={`/paiement/${id}`}
                  className="inline-flex items-center justify-center w-full px-10 py-3 text-white duration-300 bg-blue-600 rounded-full hover:bg-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                >
                  {t("buynow")}
                </Link>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FormationInfo;
