import React, { useEffect, useState } from "react";
import Main from "./Main";
// import bg from "../assets/bg.jpg";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";

const Carousel = ({ data }) => {
  const lang = localStorage.getItem("i18nextLng");
  const [index, setIndex] = useState(0);
  // const [isPaused, setIsPaused] = useState(false);
  const length = data?.length;
  const [timer, setTimer] = useState(null);

  const handlePrevious = () => {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? length - 1 : newIndex);
    clearInterval(timer);
    setTimeout(() => {
      setIndex(index === 0 ? data?.length - 1 : index - 1);
      const interval = setInterval(() => {
        setIndex((index + 1) % data.length);
      }, 5000);
      setTimer(interval);
    }, 2000);
  };

  const handleNext = () => {
    const newIndex = index + 1;
    setIndex(newIndex >= length ? 0 : newIndex);
  };

  // const handleMouseEnter = () => {
  //   setIsPaused(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsPaused(false);
  // };

  // Replace the card data with your own
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % data?.length);
    }, 8000);
    setTimer(interval);
    return () => clearInterval(interval);
  }, [index, data?.length]);

  return (
    data.length > 0 && (
      <div
        style={{}}
        className={`bg-[url('bgback.jpg')] bg-no-repeat bg-cover w-full flex relative min-h-[450px] md:min-h-[800px] lg:min-h-[640px] h-[100%] -z-0 group dark:bg-no-repeat dark:bg-cover dark:bg-center dark:bg-slate-900/90 overflow-hidden transition-all ease-in-out duration-500
      `}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        {data?.map((card, i) => (
          <Main
            key={i}
            src={card?.contenu?.image}
            title={lang === "fr" ? card?.contenu?.titre : card?.titre}
            text={lang === "fr" ? card?.contenu?.text : card?.text}
            list={lang === "fr" ? card?.contenu?.list : card?.list}
            visible={i === index ? "opacity-100" : "opacity-0 transparent"}
            style={{ transform: `translateX(-${index * 100}%)` }}
          />
        ))}
        <button
          className="flex z-40 dark:text-white dark:text-xl w-12 dark:bg-gray-200/40 h-12 rounded-full bg-gray-600/80 justify-center items-center text-white text-lg cursor-pointer 
        absolute top-[55%] md:top-[45%] -translate-x-0 left-12 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          onClick={handlePrevious}
        >
          <AiOutlineLeft className="dark:font-bold" />
        </button>
        <button
          className="flex z-40 dark:text-white dark:text-xl w-12 dark:bg-gray-200/40 h-12 rounded-full bg-gray-600/80 justify-center items-center text-white text-lg 
        absolute top-[55%] md:top-[45%] -translate-x-0 right-12 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          onClick={handleNext}
        >
          <AiOutlineRight />
        </button>
      </div>
    )
  );
};

export default Carousel;
