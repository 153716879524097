import { useGetAllNewsQuery } from "../api/newsApi";

const useNews = () => {
  const lang = localStorage.getItem("i18nextLng");
  const { data, isSuccess } = useGetAllNewsQuery({
    lang: lang,
  });
  return {
    news: isSuccess
      ? data?.news?.articles?.filter(
          (el) =>
            el.urlToImage !== null &&
            el.description !== null &&
            !el.description.includes("<")
        )
      : [],
    newsSuccess: isSuccess,
  };
};

export default useNews;
