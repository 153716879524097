import React from "react";
import Statistic from "./Statistic";
import { Link } from "react-router-dom";

const Card = ({ src, ...props }) => {
  return (
    <Link to={props.url} className="block overflow-hidden hover:shadow-xl shadow-black/5 border dark:border-none rounded-lg " target="_blank">
      <div className="overflow-hidden h-[200px] rounded">
        <img src={src} alt="" className="object-cover w-full h-full" />
      </div>
      <Statistic
        main={props.main?.slice(0, Math.round(props.main.length/2)) + "..."}
        text={props.text?.slice(0, Math.round(props.text.length/2)) + "..."}
        dark={props.dark}
        light={props.light}
        stitle={props.stitle}
        sp={props.sp}
      />
    </Link>
  );
};

export default Card;
