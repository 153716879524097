import React from "react";
import logod from "../../assets/logoy.png";
import logow from "../../assets/logo.png";
import { useTheme } from "../../hooks/ThemeContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BiLogoFacebook,
  BiLogoWhatsapp,
  BiLogoYoutube,
  BiLogoTelegram,
} from "react-icons/bi";
import logo from "../../assets/1.svg";

const file = [logo, logo];

const Footer = () => {
  const { theme, darkQuery } = useTheme();
  const { t } = useTranslation();

  return (
    typeof t("Footer", { returnObjects: true }) === "object" && (
      <>
        <footer className="bg-white dark:bg-gray-900 pt-8">
          <div className="w-full p-4 py-6 lg:py-8 px-[6.5%]">
            <div className="md:flex md:justify-between">
              <div className="mb-8">
                <Link
                  to="/"
                  className="flex gap-1 flex-nowrap items-center -ml-2 cursor-pointer"
                >
                  <img
                    src={
                      theme === "dark"
                        ? logod
                        : theme === "light"
                        ? logow
                        : theme === "system" && darkQuery.matches
                        ? logod
                        : logow
                    }
                    alt="logo"
                    className="w-10"
                  />
                  <h1 className="text-lg font-semibold dark:text-white">
                    CRYPTOCOQ
                  </h1>
                </Link>
              </div>
              <div className="grid grid-cols-2 gap-8 sm:gap-10 sm:grid-cols-3">
                {t("Footer", { returnObjects: true }).map((el, key) => {
                  if (key !== 2) {
                    return (
                      <div key={key}>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                          {el.title}
                        </h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-normal text-sm">
                          {el.links.map((el, key) => {
                            if (el.path) {
                              return (
                                <li className="mb-4" key={key}>
                                  <Link to={el.path} className="hover:underline">
                                    {el.name}
                                  </Link>
                                </li>
                              );
                            } else {
                              return (
                                <li className="mb-4" key={key}>
                                  <a
                                    href={el.ext}
                                    className="hover:underline"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {el.name}
                                  </a>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    );
                  } else {
                    return (
                      <div key={key}>
                        <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                          {el.title}
                        </h2>
                        <ul className="text-gray-500 dark:text-gray-400 font-normal text-sm">
                          {el.links.map((el, key) => {
                            return (
                              <li className="mb-4" key={key}>
                                <a
                                  href={file[key]}
                                  className="hover:underline"
                                  download={el.name + ".pdf"}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {el.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
            <div className="sm:flex p-4 py-6 lg:py-8 px-[6.5%] sm:items-center sm:justify-between border-t dark:border-gray-800/70 dark:bg-gray-900 border-gray-200">
              <span className="text-sm text-gray-800 sm:text-center dark:text-gray-400">
                © 2023{" "}
                <a href="https://flowbite.com/" className="hover:underline">
                  Crytocoq™
                </a>
                . All Rights Reserved.
              </span>
              <div className="flex mt-4 sm:justify-center sm:mt-0 text-lg">
                <a
                  href="https://web.facebook.com/elcryptocoq"
                  className="text-gray-800 hover:text-gray-900 dark:hover:text-white"
                >
                  <BiLogoFacebook />
                  <span className="sr-only">Facebook page</span>
                </a>
                <a
                  href="https://wa.link/sc6cln"
                  className="text-gray-800 hover:text-gray-900 text-lg dark:hover:text-white ms-5"
                >
                  <BiLogoWhatsapp />
                  <span className="sr-only">Whatsapp community</span>
                </a>
                <a
                  href="https://www.youtube.com/@elcryptocoq3196"
                  className="text-gray-800 hover:text-gray-900 text-lg dark:hover:text-white ms-5"
                >
                  <BiLogoYoutube />
                  <span className="sr-only">Youtube page</span>
                </a>
                <a
                  href="https://t.me/cryptocoqacademy"
                  className="text-gray-800 hover:text-gray-900 dark:hover:text-white text-lg ms-5"
                >
                  <BiLogoTelegram />
                  <span className="sr-only">Telegramme chanel</span>
                </a>
              </div>
            </div>
        </footer>
      </>
    )
  );
};

export default Footer;
