import React from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { MdDeleteOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'

const PaiementLine = ({data}) => {
  return (
    <tr className="hover:bg-gray-50 duration-100">
    <td className="py-4 ps-6">
      <div className="flex items-center justify-center hover:bg-gray-100 w-8 h-8 rounded-full">
        <input
          id="hs-table-checkbox-1"
          type="checkbox"
          className="border-gray-800 rounded-xl text-blue-600 border focus:ring-blue-500"
        />
        <label htmlFor="hs-table-checkbox-1" className="sr-only">
          Checkbox
        </label>
      </div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
      {data.phone}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
      {data.date_naissance}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
      <div className="flex gap-x-3 items-center">
        <Link to={`edit/${data._id}`} className="w-8 h-8 hover:bg-gray-100 rounded-full text-gray-600 flex items-center justify-center">
          <FiEdit2 className="text-xl"/>
        </Link>
        <Link className="w-8 h-8 hover:bg-gray-200/50 text-gray-600 rounded-full flex items-center justify-center">
          <MdDeleteOutline className="text-xl"/>
        </Link>
      </div>
    </td>
  </tr>
  )
}

export default PaiementLine