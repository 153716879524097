import { configureStore } from "@reduxjs/toolkit";
import { AuthenticationApi } from "./api/authApi";
import authSlices from "./slices/userSlice";
import { NewsApi } from "./api/newsApi";
import { UsersApi } from "./api/userApi";
import { FormationApi } from "./api/formationApi";
import { PaimentApi } from "./api/paiementApi";
import { AcademiApi } from "./api/AcademiApi";
import { ModuleApi } from "./api/ModuleApi";
import { VideoApi } from "./api/VideoApi";
import { ContenuApi } from "./api/contenuApi";
import { FormateurApi } from "./api/formateurApi";
import { actualiteApi } from "./api/actualiteApi";

export const store = configureStore({
  reducer: {
    [AuthenticationApi.reducerPath]: AuthenticationApi.reducer,
    [NewsApi.reducerPath]: NewsApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [FormationApi.reducerPath]: FormationApi.reducer,
    [PaimentApi.reducerPath]: PaimentApi.reducer,
    [AcademiApi.reducerPath]: AcademiApi.reducer,
    [ModuleApi.reducerPath]: ModuleApi.reducer,
    [VideoApi.reducerPath]: VideoApi.reducer,
    [ContenuApi.reducerPath]: ContenuApi.reducer,
    [FormateurApi.reducerPath]: FormateurApi.reducer,
    [actualiteApi.reducerPath]: actualiteApi.reducer,
    auth: authSlices,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      AuthenticationApi.middleware,
      NewsApi.middleware,
      UsersApi.middleware,
      FormationApi.middleware,
      PaimentApi.middleware,
      ModuleApi.middleware,
      AcademiApi.middleware,
      VideoApi.middleware,
      ContenuApi.middleware,
      FormateurApi.middleware,
      actualiteApi.middleware
    ),
  devTools: false,
});
